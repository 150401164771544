import axios from "axios";
import { BASE_URL } from "../../global";

export async function createSRFAnalytics(id, srfInstrumentId) {
  try {
    for (const insID of srfInstrumentId) {
      const url = `${BASE_URL}dynamic`;

      const insertQueryPayload = {
        query: `INSERT INTO srfAnalytics (srfId, srfInstrumentId) VALUES (${id}, ${insID})`,
      };
      await axios.post(url, insertQueryPayload);
      console.log("SRFid and srfInstrumentId", id, insID);
    }
  } catch (error) {
    console.error("Error creating createSRFAnalytics", error);
  }
}

export async function updateSRFAnalytics(id) {
  try {
    let progressPercent = 0;

    const url = `${BASE_URL}dynamic`;

    const findQueryPayload = {
      query: `SELECT serviceReqNumber FROM srfs WHERE id = ${id}`,
    };
    const response1 = await axios.post(url, findQueryPayload);
    const serviceReqNumber = response1.data?.[0]?.serviceReqNumber;

    const findInstrumentIDSPayload = {
      query: `SELECT srfInstrumentId FROM datasheets WHERE serviceReqNumber = '${serviceReqNumber}'`,
    };
    const InstrumentIDS = await axios.post(url, findInstrumentIDSPayload);
    const InstIDS = InstrumentIDS.data
     const srfInstrumentIdsArray = [... new Set(InstIDS?.map(item => item.srfInstrumentId))];


    for (const instrumentId of srfInstrumentIdsArray) {
    const countQueryPayload = {
      query: `SELECT COUNT(*) FROM srfAnalytics WHERE srfId = ${id} AND srfInstrumentId = ${instrumentId}`,
    };

    const response = await axios.post(url, countQueryPayload);
    const count = response.data?.[0]?.["COUNT(*)"];
    // console.log("ID and count",id,count)

    if (count == 0) {
      const insertQueryPayload = {
        query: `INSERT INTO srfAnalytics (srfId, srfInstrumentId) VALUES (${id}, ${instrumentId})`,
      };
      await axios.post(url, insertQueryPayload);
    }
    



    progressPercent += 25;
   
 

    const response2Payload = {
      query: `SELECT totalReadings FROM datasheets WHERE serviceReqNumber = '${serviceReqNumber}' AND srfInstrumentId = ${instrumentId}`,
    };
    const response2 = await axios.post(url, response2Payload);
    const totalReadings = response2?.data?.map(item => item.totalReadings);
    if ( totalReadings.some(element => element > 0)) {
      progressPercent += 25;
    }

// console.log("progressPercentResponse2",progressPercent)
    const response3Payload = {
      query: `SELECT approvedby FROM certificates WHERE serviceReqNumber = '${serviceReqNumber}' AND srfInstrumentId = ${instrumentId}`,
    };
    const response3 = await axios.post(url, response3Payload);
    const approvedby = response3?.data;
    if (approvedby?.some(item => item.approvedby !== null)) {
      progressPercent += 25;
    }

    // console.log("progressPercentResponse3",progressPercent)
    const response4Payload = {
      query: `SELECT instrumentIds FROM dispatch WHERE srfsId = ${id}`,
    };
    const response4 = await axios.post(url, response4Payload);
    const instrumentIds = response4.data?.[0]?.instrumentIds;
    const ArrayInstrumentIds = instrumentIds ? instrumentIds.split(",") : [];
    if (ArrayInstrumentIds.includes(`${instrumentId}`)) {
      progressPercent += 25;
    }
    console.log("ArrayInstrumentIds",ArrayInstrumentIds)

    const UpdateDataPayload = {
      query: `UPDATE srfAnalytics SET progressPercent = ${progressPercent} WHERE srfId = ${id} AND srfInstrumentId = ${instrumentId}`,
    };
    const UpdateData = await axios.post(url, UpdateDataPayload);

    console.log("progressPercent", progressPercent);
  }
  } catch (error) {
    console.error("Error creating createSRFAnalytics", error);
  }
}


export async function updateSRFProgress(srfInstrumentId,progressCount) {

  try{

  }catch{

  }

}