import React, { useEffect } from "react";
import { BANK_DETAILS, BASE_URL, GST_NUMBER } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { ToWords } from "to-words";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Autocomplete,
  TableContainer,
} from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import moment from "moment";
import "./viewInvoice.css";
import UnitechHeader from "../image/header.png";
import UnitechFooter from "../image/footer.png";
const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  cell50: {
    padding: 1,
    width: "50%",
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 50,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 30,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 50,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important",
  },
});

function InstrumentTable(props) {
  const toWords = new ToWords();
  let productDetails = props.productDetails;
  let productList = props.productList;
  // console.log("Product Details " + props.productDetails);
  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    courierCharges: 0,
    grandTotal: 0,
  };
  if (productDetails.length > 0) {
    for (let i = 0; i < productDetails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDetails[i]?.srfInstrumentId
      );
      productDetails[i].name = result.instrumentName;
      productDetails[i].amount =
        productDetails[i].rate * productDetails[i].quantity;
      amounts.totalAmountBeforeTax += productDetails[i]?.amount;
    }
    amounts.gst = (amounts.totalAmountBeforeTax * 18) / 100;
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;

    amounts.conveyanceCharges = parseInt(
      props.invoiceAllData?.conveyanceCharges
        ? props.invoiceAllData?.conveyanceCharges
        : "0"
    );
    amounts.courierCharges = parseInt(
      props.invoiceAllData?.courierCharges
        ? props.invoiceAllData?.courierCharges
        : "0"
    );

    amounts.roundOff = 0;
    let tmp = `${amounts.grandTotal}`.split(".");
    if (tmp.length > 1 && tmp[1].length > 0) {
      amounts.roundOff =
        Number(tmp[1]) > 0.5 ? (1 - Number(tmp[1])) * -1 : Number(tmp[1]);
      amounts.grandTotal += amounts.roundOff;
    }
    console.log(productDetails);
  }
  return (
    <Table mt={5} className={[props.classes.table]}>
      <TableHead className="single-border-table">
        <TableRow>
          <TableCell
            className={[props.classes.srCell, "special2"]}
            align="center"
            style={{ borderBottom: "none" }}
          >
            <b>Sr. No</b>
          </TableCell>
          <TableCell
            className={[props.classes.nameCell, "special2"]}
            align="center"
          >
            <b>Item Description</b>
          </TableCell>
          <TableCell
            className={[props.classes.makeCell, "special2"]}
            align="center"
          >
            <b>Discription</b>
          </TableCell>
          <TableCell
            className={[props.classes.rangeCell, "special2"]}
            align="center"
          >
            <b>Quantity</b>
          </TableCell>
          <TableCell
            className={[props.classes.qntCell, "special2"]}
            align="center"
          >
            <b>Rate</b>
          </TableCell>
          <TableCell
            className={[props.classes.rateCell, "special2"]}
            align="center"
          >
            <b>Amount</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="single-border-table">
        {productDetails.length > 0 &&
          productDetails?.map((product, i) => (
            <TableRow className={props.classes.row}>
              <TableCell className={props.classes.srCell} align="center">
                {i + 1}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="center">
                {product?.name}
              </TableCell>
              <TableCell
                className={props.classes.nameCell}
                align="center"
                style={{ minWidth: "170px" }}
              >
                {product?.discription}
              </TableCell>
              <TableCell className={props.classes.rangeCell} align="center">
                {product?.quantity}
              </TableCell>
              <TableCell className={props.classes.qntCell} align="center">
                {product?.rate}
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                {product?.amount}
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            <b>Onsites Charges &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>
              {" "}
              {amounts.conveyanceCharges
                ? amounts.conveyanceCharges.toFixed(2)
                : 0}
            </b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            <b> Courier Charges &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>
              {amounts.courierCharges ? amounts.courierCharges.toFixed(2) : 0}
            </b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            <b>Total Amount &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.totalAmountBeforeTax.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            {/* {productDeatails[0]?.qt_gstType === 1 ? (
            <b>Total GST &nbsp;</b>
          ) : (
          )} */}
            <b>Total GST(CGST and SGST) &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.gst.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            <b>Round Off &nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.roundOff ? amounts.roundOff.toFixed(2) : 0}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            <b>Discount&nbsp;</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b></b>
          </TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: "#D9D9D9" }}>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="right"
          >
            <b>Total Amount &nbsp;</b>
          </TableCell>
          <TableCell
            style={{ padding: 1, width: 50, height: 5 }}
            align="center"
          >
            <b></b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.grandTotal ? amounts.grandTotal.toFixed(2) : ""}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={6}
            align="left"
          >
            <div>
              <b style={{ backgroundColor: "#D9D9D9", padding: "1px 5px" }}>
                &nbsp; Amount in Words:
              </b>
              <b>
                &nbsp;
                {toWords.convert(
                  amounts.grandTotal ? amounts.grandTotal.toFixed(2) : 0,
                  { currency: true }
                )}
              </b>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default function ViewCustomInvoice() {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState({});
  const [termsAndConditions, setTermAndCondition] = React.useState();
  const [invoiceData, setInvoiceData] = React.useState({});
  const [gstType, setGstType] = React.useState(2);
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [contact, setContact] = React.useState("");
  const [invoiceAllData, setinvoiceAllData] = React.useState([]);

  console.log("invoiceAllData",invoiceAllData)

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [90, 1, 100, 1],
        filename: `certificate_${params.id}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      // let image = await generateDynamicHeader(
      //   ulrNumber,
      //   certificateNumber,
      //   i,
      //   totalPages
      // );
      // pdf.addImage(image, 0, 70, 595, 55);

      let image = await generateDynamicFooter(i, totalPages);
      pdf.addImage(image, 0, 740, 595, 50);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 785, 595, 55);
      }
    }

    pdf.save();
  };

  const fetchSettings = () => {
    // Terms and Condition
    let url = BASE_URL;
    axios
      .get(url + "settings?_where=(keyName,eq,Invoice Terms)&_fields=value")
      .then((res) => {
        if (res.data.length > 0) setTermAndCondition(res.data[0].value);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from customProducts as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    axios
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getInvoiceData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=invoice.customInvoice,_j,client.clients,_j,pMaster.paymentMaster&_on1=(invoice.clientId,eq,client.id)&_on2=(invoice.poNumber,eq,pMaster.poNumber)&_fields=pMaster.id,pMaster.discount,invoice.invoiceDate,invoice.gstType,invoice.challanNo,invoice.challanDate,invoice.invoiceType,client.companyName,invoice.clientId,client.address,client.contact,invoice.poNumber,invoice.poDate,invoice.subject&_where=(invoice.id,eq,${params.mainId})~and(invoice.status,eq,1)`
      )
      .then((res) => {
        console.log("custominvoice", res.data);
        let tmp = res.data[0];
        tmp.client_contact =
          tmp.client_contact.length > 0 &&
          JSON.parse(tmp.client_contact) &&
          JSON.parse(tmp.client_contact)[0]
            ? setContact(JSON.parse(tmp.client_contact)[0])
            : {};
        setInvoiceData(res.data[0]);
        setGstType(res.data[0].invoice_gstType);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };
  const fetchInvoiceDetails = (inpt) => {
    let url = BASE_URL;
    axios
      .get(url + `customInvoice/${params.mainId}`)
      .then((res) => {
        let invoiceData = res.data[0];
        setinvoiceAllData(invoiceData)
        console.log("invoiceData", invoiceData);
        if (invoiceData.productDetails) {
          setProductDetails(
            invoiceData.productDetails == ""
              ? ""
              : JSON.parse(invoiceData.productDetails)
          );
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  useEffect(() => {
    // getCustomReadingsArray();
    fetchSettings();
    getProductList();
    getInvoiceData();
  }, []);
  useEffect(() => {
    fetchInvoiceDetails();
  }, [params.mainId]);

  return (
    <>
      <Paper sx={{ mx: 5, p: 2 }}>
        <Grid container spacing={2} align="left" style={{ display: "flex" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { id: 1, label: "With Letter Head" },
                { id: 2, label: "Without Letter Head" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="letterHead" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Letter Head" ||
                  newInputValue === ""
                ) {
                  setLetterHead(true);
                } else if (newInputValue === "Without Letter Head") {
                  setLetterHead(false);
                }
                // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { id: 1, label: "With Electronically Signed" },
                { id: 2, label: "Without  Electronically Signed" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Electronically Signed" />
              )}
              onInputChange={(event, newInputValue) => {
                if (
                  newInputValue === "With Electronically Signed" ||
                  newInputValue === ""
                ) {
                  setElectronicSign(true);
                } else if (newInputValue === "Without  Electronically Signed") {
                  setElectronicSign(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                generatePDF();
              }}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
        <div className="invoice" ref={printComponentRef}>
          <div style={{ width: "100%" }} id="header-section"></div>

          <div
            style={{
              width: "95%",
              border: "1px solid black",
              textAlign: "center",
              margin: "10px auto",
              padding: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid md={12} className="font-link" align="center">
                <h5 style={{ color: "#000066", margin: "10px 0px" }}>
                  <b>Tax Invoice</b>
                </h5>
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                        width: "22%",
                      }}
                    >
                      Invoice No
                    </TableCell>
                    <TableCell align="left" style={{ width: "28%" }}>
                      {invoiceData?.invoice_poNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                        width: "22%",
                      }}
                    >
                      Invoice Date
                    </TableCell>
                    <TableCell align="left" style={{ width: "28%" }}>
                      {moment(invoiceData?.challan_date).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="left"
                      style={{
                        fontWeight: "600",
                        width: "50%",
                      }}
                    >
                      To,
                      <br />
                      {invoiceData?.client_companyName}
                      <br />
                      {invoiceData?.client_address}
                    </TableCell>
                    {/* <TableCell align="left" style={{ width: "28%" }}>
                        {details.address}
                      </TableCell> */}
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                        width: "22%",
                      }}
                    >
                      Challan No.
                      <br />
                      Challan Date.
                      <br />
                      P.O. No.
                      <br />
                      P.O. Date.
                    </TableCell>
                    <TableCell align="left" style={{ width: "28%" }}>
                      {invoiceData?.invoice_challanNo}
                      <br />
                      {moment(invoiceData?.invoice_challanDate).format(
                        "DD/MM/YYYY"
                      )}
                      <br />
                      {invoiceData?.invoice_poNumber}
                      <br />
                      {moment(invoiceData?.invoice_poDate).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                        width: "22%",
                      }}
                    >
                      GST NO
                    </TableCell>
                    <TableCell align="left" style={{ width: "28%" }}>
                      {GST_NUMBER}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                        width: "22%",
                      }}
                    >
                      Refn . our Dispatch
                    </TableCell>
                    <TableCell align="left" style={{ width: "28%" }}>
                      Refn . our Dispatch
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Kind Attn
                    </TableCell>
                    <TableCell align="left">{contact.contact}</TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Payment
                    </TableCell>
                    <TableCell align="left">100% Against Delivery </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <InstrumentTable
              classes={classes}
              productDetails={productDetails}
              gstType={gstType}
              productList={productList}
              invoiceAllData={invoiceAllData}
            ></InstrumentTable>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                margin: "0px auto",
                border: "1px solid black",
                borderTop: "none",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  borderRight: "1px solid black",
                  padding: "5px",
                  width: "50%",
                }}
              >
                <b>Bank Details For Payment - </b>
                <br />
                {BANK_DETAILS["Bank Name"]}
                <br />
                <b>Bank A/c. No. :</b>
                {BANK_DETAILS["Bank A/c. No."]}
                <br />
                <b>IFSC Code :</b>
                {BANK_DETAILS["IFSC Code"]}
                <br />
                <b>GST NO :</b>
                {GST_NUMBER}
              </div>
              <div
                style={{
                  width: "50%",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  justifyContent: "space-between",
                }}
              >
                <b>For BFI , Mumbai</b>
                <b>Authorized Signatory</b>
              </div>
            </div>
            <div style={{ textAlign: "left" }}>
              <b>Note :-</b>
              <span>
                Material return as it after calibration with calibration
                certificates - Yes / No
              </span>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}
