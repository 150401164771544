import convert from "./convert-units/lib/convert_units";

const unitMapping = {
  "kg/cm²": "Kg_cm_sq",
  "kg/cm2": "Kg_cm_sq",
  "°C": "C",
  μm: "micro",
  µm: "micro",
};

const FSD_percentageToUnit = (P, L, H) => {
  return L + ((H - L) / 100) * P;
};

const FSD_unitToPercentage = (V, L, H) => {
  return ((H - L) * (V - L)) / 100;
};

const RDG_percentageToUnit = (P, CP) => {
  return (CP * P) / 100;
};

const RDG_unitToPercentage = (V, CP) => {
  return (V * 100) / CP;
};

const getUnitForLibrary = (unit) => {
  if (unit.toLowerCase() in unitMapping) return unitMapping[unit];
  return unit;
};

//V1 and V2 is Lower/from Range and Higher/to Range from FSD
//V1 is for Calibration point for RGD and V2 is agnored
export default function unitConvertor(value, fromUnit, toUnit, V1, V2) {
  const precision = getPrecision(value);

  if (fromUnit === toUnit) return value;

  if (fromUnit === "%FSD" && V1 && V2)
    return FSD_percentageToUnit(value, V1, V2);
  else if (fromUnit === "%RDG" && V1) return RDG_percentageToUnit(value, V1);

  if (toUnit === "%FSD" && V1 && V2) return FSD_unitToPercentage(value, V1, V2);
  else if (toUnit === "%RDG" && V1) return RDG_unitToPercentage(value, V1);

  let convertedValue = convert(value * 1)
    .from(getUnitForLibrary(fromUnit.trim()))
    .to(getUnitForLibrary(toUnit.trim()));

  return convertedValue.toFixed(precision);
}

function getPrecision(value) {
  const valueString = value.toString();
  const decimalIndex = valueString.indexOf(".");

  if (decimalIndex === -1) {
    return 0;
  }

  return valueString.length - decimalIndex - 1;
}

export const isCovertPossible = (from, to) => {
  let possibilities;
  try {
    possibilities = convert().from(getUnitForLibrary(from)).possibilities();
  } catch (e) {
    console.log(e);
    return false;
  }
  return possibilities?.includes(getUnitForLibrary(to)) || false;
};
