import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "./../../global";
import axios from "axios";

const ViewRemarks = () => {
  const location = useLocation();
  const [auditData1, setAuditData] = useState([]);
  const printComponentRef = React.useRef();

  const queryIdArray = new URLSearchParams(location.search).get("id");
  let AuditIDArray = JSON.parse(queryIdArray);

  const getData = async (auditId) => {
    try {
      let data = {
        query: `select a.id as id, a.type as type, a.identifier as identifier, usr.userName as user, a.time as time, a.action as action, a.remarks as remarks from audit as a join users as usr on usr.id = a.user where a.id=${auditId}`,
      };
      const res = await axios.post(BASE_URL + `dynamic`, data);
      return res.data;
    } catch (err) {
      console.log("Data fetching error: ", err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchDataForAllIds = async () => {
      const promises = AuditIDArray.map((auditId) => getData(auditId));

      try {
        const results = await Promise.all(promises);
        setAuditData(results.flat());
      } catch (err) {
        console.log("error", err);
      }
    };

    if (AuditIDArray && AuditIDArray.length > 0) {
      fetchDataForAllIds();
    }
  }, []);

  const data = auditData1.map((ele) => {
    const Remarks = ele.remarks ? ele.remarks.toString() : "";
    const rows =
      ele.action.toLocaleLowerCase() === "reading updated"
        ? Remarks.split("|").filter((row) => row.trim() !== "")
        : [Remarks];

    return rows.map((row) => {
      const changes = row.split(",").map((change) => {
        const countColon = (change.match(/:/g) || []).length;

        const part = change.trim().split(":");
        if (countColon == 2) {
          const columnName = part[1];
          const values = part[2];
          const [previousValue, updatedValue] = values.split(" >> ");
          return { columnName, previousValue, updatedValue };
        } else {
          const columnName = part[0];
          const values = part.filter((_, i) => i !== 0).join(":");
          const [previousValue, updatedValue] = values
            .split(" >> ")
            .map((e) =>
              (e || "").replaceAll("||", ",").replaceAll("|", " to ")
            );
          return { columnName, previousValue, updatedValue };
        }
      });

      return changes;
    });
  });

  const generatePDF = () => {
    const element = printComponentRef.current;

    html2pdf()
      .from(element)
      .set({
        margin: [40, 0, 40, 0],
        filename: `AuditData_.pdf`,
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy'],
        // },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(9);
          pdf.setTextColor(0, 0, 0);
          pdf.setFont("Courier");
        }
      })
      .catch((err) => console.log("certificate error : ", err))
      .save();
  };

  const textStyle = {
    fontWeight: "bold",
    display: "inline-block",
    minWidth: "120px",
  };

  const tableStyle = {
    backgroundColor: "#1976d2",
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        sx={{ ml: 3 }}
        onClick={() => {
          generatePDF();
        }}
      >
        Download PDF
      </Button>
      <div ref={printComponentRef}>
        <div></div>
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {data.map((tableData, tableIndex) => (
            <div
              key={tableIndex}
              style={{
                marginTop: "30px",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Divider sx={{ borderTop: "2px solid" }} />
              <Typography
                variant="h6"
                sx={{ textAlign: "left", margin: "10px", fontWeight: "bold" }}
                color="initial"
              >
                Table of {auditData1[tableIndex].type}
              </Typography>
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  marginBottom: "20px",
                  width: "40%",
                  minWidth: "585px",
                  //  margin: "auto",
                  textAlign: "left",
                }}
              >
                <Typography variant="h6">
                  <span style={textStyle}>Type:</span>{" "}
                  {auditData1[tableIndex].type}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>Identifier:</span>{" "}
                  {auditData1[tableIndex].identifier}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>User:</span>{" "}
                  {auditData1[tableIndex].user}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>Time:</span>{" "}
                  {new Date(auditData1[tableIndex].time).toLocaleString()}
                </Typography>

                <Typography variant="h6">
                  <span style={textStyle}>Action:</span>{" "}
                  {auditData1[tableIndex].action}
                </Typography>
              </Paper>

              {tableData.map((row, rowIndex) => (
                <Table
                  stickyHeader
                  aria-label={`sticky table ${tableIndex}`}
                  class="table-responsive"
                  size="small"
                  style={{ marginTop: "50px" }}
                  id={`datatable-keytable-${tableIndex}`}
                >
                  <TableHead>
                    <TableRow style={tableStyle}>
                      <TableCell style={tableStyle}>Column Name</TableCell>
                      <TableCell style={tableStyle}>Previous Value</TableCell>
                      <TableCell style={tableStyle}>Updated Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.map((column, columnIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>{column?.columnName}</TableCell>
                        <TableCell>{column?.previousValue}</TableCell>
                        <TableCell>{column?.updatedValue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewRemarks;
