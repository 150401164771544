import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";

import SummarizeIcon from "@mui/icons-material/Summarize";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import InfoIcon from "@mui/icons-material/Info";
import StarBorder from "@mui/icons-material/StarBorder";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import BuildIcon from "@mui/icons-material/Build";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TableViewIcon from "@mui/icons-material/TableView";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Menu, MenuItem, Modal, Tooltip } from "@mui/material";
import { BASE_URL, DISABLED_MODULE } from "./../../global";
import axios from "axios";
import { NotificationList } from "../notification/Notification";

export const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [masterNested, setMasterNested] = React.useState(false);
  const [srfNested, setSrfNested] = React.useState(false);
  const [paymentNested, setPaymentNested] = React.useState(false);
  const [CommercialNested, setCommercialNested] = React.useState(false);
  const [intermediateProcedure, setIntermediateProcedure] =
    React.useState(false);
  const [notificationsNo, setNotificationsNo] = React.useState(0);
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);
  // const selectedRouteInUpperCase = selectedRoute

  const [isModalOpen, setModalOpen] = useState(false);
  const storage_userName = localStorage.getItem("userName");
  const storage_userType = localStorage.getItem("type");
  const storage_modules = localStorage
    .getItem("modules")
    ?.split(",")
    .map((e) => e)
    .map((e) => Number(e));

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  const hasAccessToModule = (key) => {
    key = Number(key);
    if (DISABLED_MODULE?.includes(key)) return false;
    if (`${storage_userType}` === "1") return true;
    else if (`${storage_userType}` === "2" || `${storage_userType}` == "4") {
      if (storage_modules?.includes(key)) return true;
      else return false;
    } else {
      if ([1, 5, 11, 12, 26, 27].includes(key)) return true;
      else return false;
    }
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
    crossTab: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open3 = Boolean(anchorEl2);

  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    if (props.rolesInfo.roles && props.rolesInfo.roles?.length > 1)
      setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const nav_modules = [
    {
      type: "item",
      component: Link,
      path: "/dashboard",
      icon: <DashboardIcon />,
      name: "Dashboard",
      moduleId: "1",
    },
    {
      type: "item",
      component: Link,
      icon: <FormatListBulletedIcon />,
      name: "Master",
      moduleId: "1",
      onClick: (e) => setMasterNested(!masterNested),
      extra: masterNested ? <ExpandLess /> : <ExpandMore />,
    },
  ];

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            align="left"
          >
            {selectedRouteInUpperCase}
          </Typography>
          <Tooltip style={{ textTransform: "uppercase" }}>
            <IconButton
              // onClick={handleClick2}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open2 ? "Role" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              style={{ backgroundColor: "white", marginRight: "5px" }}
              disabled
            >
              <AccountCircleIcon style={{ color: "#1976d2" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="User" style={{ textTransform: "uppercase" }}>
            &nbsp;
            {storage_userName} &nbsp;
          </Tooltip>
          <Tooltip title="User Type" style={{ textTransform: "uppercase" }}>
            (
            {
              ["admin", "Engineer", "client", "branch admin"][
                Number(storage_userType) - 1
              ]
            }
            )
          </Tooltip>
          <Tooltip title="Role" style={{ textTransform: "uppercase" }}>
            {props.rolesInfo.roles?.length ? (
              <Button
                onClick={handleClick2}
                size="small"
                aria-controls={open2 ? "Role" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                style={{
                  color: "white",
                  width: "193px",
                  justifyContent: "left",
                  textDecorationLine: "underline",
                }}
                disabled={
                  !props.rolesInfo.roles || props.rolesInfo.roles?.length === 1
                }
              >
                ({props.rolesInfo.currentRole?.name || ""})
              </Button>
            ) : (
              ""
            )}
          </Tooltip>
          <Tooltip title="Notification">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open2 ? "available-roles" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              style={{ backgroundColor: "white" }}
            >
              <Badge
                badgeStyle={{
                  top: 12,
                  right: 12,
                  backgroundColor: "white",
                  border: "2px solid black",
                  color: "white",
                  fontWright: 900,
                }}
                color="secondary"
                badgeContent={`${notificationsNo}`}
                secondary={true}
              >
                <NotificationsNoneIcon style={{ color: "#1976d2" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          &nbsp; |
          <Button color="inherit" onClick={logout}>
            &nbsp;&nbsp; Logout &nbsp;&nbsp;
          </Button>
          <Button color="inherit" onClick={handleButtonClick}>
            <InfoIcon />
          </Button>
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "24px",
                backgroundColor: "#fff",
                position: "fixed",
                top: "7%",
                right: "0px",
                width: "auto",
                textAlign: "right",
                boxSizing: "border-box",
                color: "#fff",
              }}
            >
              <span
                style={{
                  backgroundColor: "#000",
                  padding: "3px",
                }}
              >
                LABCITY Software version 2.0.0
              </span>
            </div>
          </Modal>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" gutterBottom component="div">
            Calibration
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {/* {hasAccessToModule("1") && !DISABLED_MODULE?.includes("Dashboard") && (
            <ListItemButton component={Link} to="/dashboard" onClick={() => {}}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          )} */}

          {nav_modules.map((module) => {
            if (module.type === "item") {
              if (module.component)
                return (
                  hasAccessToModule(module.moduleId) &&
                  (module.to ? (
                    <ListItemButton
                      component={module.component}
                      to={module.path}
                      onClick={module.onClick}
                    >
                      <ListItemIcon>{module.icon}</ListItemIcon>
                      <ListItemText primary={module.name} />
                      {module.extra || ""}
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={module.onClick}>
                      <ListItemIcon>{module.icon}</ListItemIcon>
                      <ListItemText primary={module.name} />
                      {module.extra || ""}
                    </ListItemButton>
                  ))
                );
            }
          })}
          {/* {hasAccessToModule("2") && !DISABLED_MODULE?.includes("Master") && (
            <ListItemButton onClick={(e) => setMasterNested(!masterNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Master" />
              {masterNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )} */}
          <Collapse in={masterNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {!DISABLED_MODULE?.includes("Master EQP") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/masterEQP"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary="Master EQP" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Instrument") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/instrument"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <PrecisionManufacturingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Instrument" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("DUC Master") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/ducMasterList"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <AcUnitIcon />
                  </ListItemIcon>
                  <ListItemText primary="DUC Master" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Discipline") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/discipline"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <ArchitectureIcon />
                  </ListItemIcon>
                  <ListItemText primary="Discipline" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Price List") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/product"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <ArchitectureIcon />
                  </ListItemIcon>
                  <ListItemText primary="Price List" />
                </ListItemButton>
              )}
              {/* <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/range"
                onClick={() => {}}>
                <ListItemIcon>
                  <ArchitectureIcon />
                </ListItemIcon>
                <ListItemText primary="Range" />
              </ListItemButton> */}
              {!DISABLED_MODULE?.includes("Client") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/client"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Client" />
                </ListItemButton>
              )}
              {/* <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/table"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Table" />
              </ListItemButton> */}
              {!DISABLED_MODULE?.includes("Units") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/unit/unitList"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Units" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Template") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/template"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Template" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Datasheet Template") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/datasheetTemplate"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Datasheet Template" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Uncertainty") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/uncertinity/uncertinityList"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Uncertainty" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Supportive Inst") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/supportiveInstruments/supportiveInstrumentsList"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Supportive Inst" />
                </ListItemButton>
              )}
              {!DISABLED_MODULE?.includes("Settings") && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/master/setting"
                  onClick={() => {}}
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              )}
            </List>
          </Collapse>

          {hasAccessToModule(3) && !DISABLED_MODULE?.includes("SRF") && (
            <List component="div" disablePadding>
              <ListItemButton
                // sx={{ pl: 4 }}
                component={Link}
                to="/srf"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="SRF" />
              </ListItemButton>
            </List>
          )}

          {hasAccessToModule("4") &&
            !DISABLED_MODULE?.includes("Datasheets") && (
              <ListItemButton
                component={Link}
                to="/datasheet"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <TableViewIcon />
                </ListItemIcon>
                <ListItemText primary="Datasheets" />
              </ListItemButton>
            )}
          {hasAccessToModule("5") &&
            !DISABLED_MODULE?.includes("Certificates") && (
              <ListItemButton
                component={Link}
                to="/certificate"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Certificates" />
              </ListItemButton>
            )}
          {/* {hasAccessToModule("") && (
            <ListItemButton
              component={Link}
              to="/challan/challanList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Challan List" />
            </ListItemButton>
          )} */}

          {hasAccessToModule("7") &&
            !DISABLED_MODULE?.includes("Courier Register") && (
              <ListItemButton
                component={Link}
                to="/courier/courierList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Courier Register" />
              </ListItemButton>
            )}
          {hasAccessToModule("8") && !DISABLED_MODULE?.includes("Expenses") && (
            <ListItemButton
              component={Link}
              to="/master/expense"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Expenses" />
            </ListItemButton>
          )}
          {hasAccessToModule("9") &&
            !DISABLED_MODULE?.includes("Work Report") && (
              <ListItemButton
                component={Link}
                to="/workReport"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Work Report" />
              </ListItemButton>
            )}
          {hasAccessToModule("10") &&
            !DISABLED_MODULE?.includes("Summary Report") && (
              <ListItemButton
                component={Link}
                to="/summaryReport"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Summary Report" />
              </ListItemButton>
            )}

          {hasAccessToModule("11") &&
            !DISABLED_MODULE?.includes("History Card") && (
              <ListItemButton
                component={Link}
                to="/historyCard"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="History Card" />
              </ListItemButton>
            )}
          {hasAccessToModule("12") &&
            !DISABLED_MODULE?.includes("Scheduler") && (
              <ListItemButton
                component={Link}
                to="/scheduler"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Scheduler" />
              </ListItemButton>
            )}
          {hasAccessToModule("14") &&
            !DISABLED_MODULE?.includes("Commercial") && (
              <ListItemButton
                onClick={(e) => setCommercialNested(!CommercialNested)}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary="Commercial" />
                {CommercialNested ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
          <Collapse in={CommercialNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/enquiry/enquiryList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <NewspaperIcon />
                </ListItemIcon>
                <ListItemText primary="Enquiry" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/enquiry/followUpReport"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary="Enquiry Followup" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/quotation"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <RequestQuoteIcon />
                </ListItemIcon>
                <ListItemText primary="Quotation" />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/poAcceptance"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PodcastsIcon />
                </ListItemIcon>
                <ListItemText primary="poAcceptance" />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/viewInvoice"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Invoice" />
              </ListItemButton>
            </List>
          </Collapse>

          {hasAccessToModule("17") && !DISABLED_MODULE?.includes("Payment") && (
            <ListItemButton onClick={(e) => setPaymentNested(!paymentNested)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Payment" />
              {paymentNested ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={paymentNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/paymentMaster"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Payment History" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/accountStatement"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Account Statement" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/gstReport"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Gst Report" />
              </ListItemButton>
            </List>
          </Collapse>
          {hasAccessToModule("18") &&
            !DISABLED_MODULE?.includes("Purchase") && (
              <ListItemButton
                component={Link}
                to="/master/purchase/purchaseList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Purchase" />
              </ListItemButton>
            )}
          {/* {hasAccessToModule("19") && (
            <ListItemButton
              onClick={(e) => setIntermediateProcedure(!intermediateProcedure)}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="Intermediate Procedure" />
              {intermediateProcedure ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )} */}
          {/* {hasAccessToModule("20") && (
            <ListItemButton
              component={Link}
              to="/master/scope/scopes"
              onClick={() => {}}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Nable Scope" />
            </ListItemButton>
          )} */}

          {hasAccessToModule("21") &&
            !DISABLED_MODULE?.includes("Master In/Out") && (
              <ListItemButton
                component={Link}
                to="/standardInOutList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Master In/Out" />
              </ListItemButton>
            )}

          {/* {hasAccessToModule("23") && (
            <ListItemButton
              component={Link}
              to="/feedback/feedbackReport"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback Report" />
            </ListItemButton>
          )}

          {hasAccessToModule("24") && (
            <ListItemButton
              component={Link}
              to="/master/pickup/pickupList"
              onClick={() => {}}
            >
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Pickup" />
            </ListItemButton>
          )} */}
          {hasAccessToModule("25") &&
            `${storage_userType}` === "1" &&
            !DISABLED_MODULE?.includes("Customer Complaints") && (
              <ListItemButton
                component={Link}
                to="/complaintsList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <DomainDisabledIcon />
                </ListItemIcon>
                <ListItemText primary="Customer Complaints" />
              </ListItemButton>
            )}
          {hasAccessToModule("25") &&
            `${storage_userType}` === "1" &&
            !DISABLED_MODULE?.includes("Customer Feedback") && (
              <ListItemButton
                component={Link}
                to="/feedbackList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                <ListItemText primary="Customer Feedback" />
              </ListItemButton>
            )}
          {hasAccessToModule("25") &&
            `${storage_userType}` === "1" &&
            !DISABLED_MODULE?.includes("User") && (
              <ListItemButton
                component={Link}
                to="/master/users/userList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItemButton>
            )}
          {hasAccessToModule("-1") &&
            `${storage_userType}` === "1" &&
            !DISABLED_MODULE?.includes("Audit") && (
              <ListItemButton component={Link} to="/audit" onClick={() => {}}>
                <ListItemIcon>
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Audit" />
              </ListItemButton>
            )}

          {hasAccessToModule("5") &&
            !DISABLED_MODULE?.includes("Request Form") && (
              <ListItemButton
                component={Link}
                to="/requestFormList"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Request Form" />
              </ListItemButton>
            )}

          {hasAccessToModule("25") &&
            `${storage_userType}` === "1" &&
            !DISABLED_MODULE?.includes("Document Upload") && (
              <ListItemButton
                component={Link}
                to="/documentUpload"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary="Document Upload" />
              </ListItemButton>
            )}
          <Collapse in={intermediateProcedure} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/intermediateProcedure"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Intermediate Procedure" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/master/checkRecords"
                onClick={() => {}}
              >
                <ListItemIcon>
                  <PrecisionManufacturingIcon />
                </ListItemIcon>
                <ListItemText primary="Check Records" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>
      <NotificationList
        anchorEl={anchorEl}
        open={open2}
        close={handleClose}
        notifyNumber={(n) => setNotificationsNo(n)}
      />
      <Menu
        anchorEl={anchorEl2}
        id="available-roles"
        open={open3}
        onClose={handleClose2}
        onClick={handleClose2}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {(props.rolesInfo?.roles || [])
          .filter((e) => (storage_userType == 3 ? e.client : !e.client))
          .map((role) => (
            <MenuItem
              onClick={() => {
                props.rolesInfo.setCurrentRole(role);
              }}
            >
              {role.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
