import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextareaAutosize,
  Stack,
  Modal,
  Box,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { showComponent } from "../helper/helpers";
import { ClassicTable } from "./../../utils/components/Styles";
import jsPDF from "jspdf";
import generateAudit from "../audit/audit";

const axios = require("axios");

const fixedColumns = [
  {
    field: "id",
    headerName: "Sr. No.",
    editable: false,
  },
  {
    field: "description",
    headerName: "Instruments",
    editable: true,
  },
  {
    field: "ducID",
    headerName: "DUCID",
    editable: true,
  },
  {
    field: "serialNumber",
    headerName: "Serial number",
    editable: true,
  },
  {
    field: "range",
    headerName: "DUC Range",
    editable: true,
  },
  {
    field: "lc",
    headerName: "Least Count",
    editable: true,
  },
  {
    field: "make",
    headerName: "DUC Make",
    editable: true,
  },
  {
    field: "model",
    headerName: "Model",
    editable: true,
  },
  {
    field: "conOfDuc",
    headerName: "Con. Of Duc",
    editable: true,
  },
  {
    field: "calibrationFrequency",
    headerName: "Calibration frequency",
    editable: true,
  },
  {
    field: "accuracy",
    headerName: "Accuracy",
    editable: true,
  },
  {
    field: "calibrationType",
    headerName: "Calibration Type",
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    editable: true,
  },
  {
    field: "locationOfInstrument",
    headerName: "Location Of Instrument",
    editable: true,
  },
  {
    field: "calPoint",
    headerName: "Calibration Point",
    editable: true,
  },
  {
    field: "calMethod",
    headerName: "Calibration Method",
    editable: true,
  },
  {
    field: "labCapabilities",
    headerName: "Lab Capabilities",
    editable: true,
  },
];

export default function CreateSRF(props) {
  const navigate = useNavigate();
  const params = useParams();
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];

  const [state, setState] = React.useState({
    srfInstruments: [],
    allInstruments: [],
    clientName: "",
    clientId: "",
    receiptDate: "",
    poNumber: "",
    poDate: "",
    committedDeliveryDate: "",
    serviceReqNumber: "",
    jobNumber: "",
    description: "",
    dcNumber: "",
    dcDate: "",
    entryDate: "",
    address: "",
    readingRows: [],
    fullColumnsLoaded: false,
    multiValueData: {},
    multiValueModalOpen: false,
    unitArray: [],
    copyQt: [],
    readingColumns: [fixedColumns],
    oldData: {},
  });

  const errorFn = (errTxt, err) => {
    console.error(errTxt, err);
    return ["err", err];
  };

  const getData = () => {
    Promise.all([
      getUnitMasterArray(),
      getSrfsList(),
      srfInstrumentsList(),
      getExtraColumns(),
      instrumentsList(),
    ])
      .then((res) => {
        let st = { ...state };
        if (res[0][0] !== "err") st.unitArray = res[0][0];
        if (res[1][0] !== "err") {
          st.serviceReqNumber = res[1][0][0].srf_serviceReqNumber;
          st.jobNumber = res[1][0][0].srf_jobNumber;
          st.poNumber = res[1][0][0].srf_poNumber;
          st.poDate = res[1][0][0].srf_poDate;
          st.receiptDate = res[1][0][0].srf_receiptDate;
          st.dcNumber = res[1][0][0].srf_dcNumber;
          st.dcDate = res[1][0][0].srf_dcDate;
          st.entryDate = res[1][0][0].srf_entryDate;
          st.description = res[1][0][0].srf_description;
          st.committedDeliveryDate = res[1][0][0].srf_committedDeliveryDate;
          st.address = res[1][0][0].client_address;
          st.clientName = res[1][0][0].client_companyName;
          st.clientId = res[1][0][0].client_id;
        }
        if (res[2][0] !== "err") st.srfInstruments = res[2][0];
        if (res[3][0] !== "err") {
          st.readingColumns = res[3][0];
          st.fullColumnsLoaded = true;
        }
        if (res[4][0] !== "err") st.allInstruments = res[4][0];

        let rows = [];
        for (let i = 0; i < res[2][0].length; i++) {
          let extra = res[2][0][i].extraColumns
            ? JSON.parse(res[2][0][i].extraColumns)
            : {};
          rows.push(
            [
              res[2][0][i].id,
              res[2][0][i].instrumentId,
              res[2][0][i].DUCID,
              res[2][0][i].serialNumber,
              res[2][0][i].ranges,
              res[2][0][i].lc,
              res[2][0][i].make,
              res[2][0][i].model,
              res[2][0][i].ConOfDuc,
              res[2][0][i].calFrequency,
              res[2][0][i].accuracy,
              res[2][0][i].calibrationType,
              res[2][0][i].location,
              res[2][0][i].locationOfInstrument,
              res[2][0][i].calPoint,
              res[2][0][i].calMethod,
              res[2][0][i].labCapabilities,
            ].concat(
              res[2][0][i].extraColumns
                ? Array(res[3][0][0].length - fixedColumns.length)
                    .fill("")
                    .map((e, j) => {
                      return extra[res[3][0][0][fixedColumns.length + j].field]
                        ? extra[res[3][0][0][fixedColumns.length + j].field]
                        : e;
                    })
                : Array(res[3][0][0].length - fixedColumns.length).fill("")
            )
          );
        }

        for (let i = 0; i < rows.length; i++) {
          for (let j = 0; j < res[4][0].length; j++) {
            if (res[4][0][j].id === rows[i][1]) {
              rows[i][1] = rows[i][1] + ", " + res[4][0][j].instrumentName;
              // delete rows[i][0];
            }
          }
        }
        console.log(rows);
        let rRs = {};
        rows.map((r) => {
          rRs[r[0]] = [...r];
        });
        setState({
          ...st,
          readingRows: [...rows],
          copyQt: Array(rows.length).fill(1),
          oldData: {
            ...st,
            readingRows: { ...rRs },
            copyQt: Array(rows.length).fill(1),
          },
        });
        console.log("MainState", state);
      })
      .catch((err) => errorFn("Something Went Wrong at (getDta) : ", err));
  };

  const multiValueModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    maxHeight: "350px",
    overflow: "auto",
  };

  const renderMultivalueModal = () => {
    if (
      state.multiValueData.rowIndex != undefined &&
      state.multiValueData.cellIndex != undefined
    )
      return (
        <Modal
          open={state.multiValueModalOpen}
          onClose={() => {
            // setMultiValueModalOpen(false);
            setState({ ...state, multiValueModalOpen: false });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          size="md"
        >
          <Box sx={multiValueModalStyle}>
          <Box sx={{display:'flex' , justifyContent:"space-between",alignItems:"center" , my:"5px"}}>
            <h5>Update Multiple values</h5>
            <Button variant="contained" size="small" onClick={()=>setState({ ...state, multiValueModalOpen: false })}>X</Button>
            </Box>
            <table className="srf-multivalue-table">
              <tr>
                {state.multiValueData.cellIndex === 4 ||
                state.multiValueData.cellIndex === 19 ? (
                  <th>Min Value</th>
                ) : (
                  ""
                )}
                {state.multiValueData.cellIndex === 4 ||
                state.multiValueData.cellIndex === 19 ? (
                  <th>{" - "}</th>
                ) : (
                  ""
                )}
                <th>
                  {state.multiValueData.cellIndex === 4 ||
                  state.multiValueData.cellIndex === 19
                    ? "Max Value"
                    : "Value"}
                </th>
                <th>Unit</th>
                <th></th>
              </tr>
              {state.readingRows[state.multiValueData.rowIndex][
                state.multiValueData.cellIndex
              ]
                .split("||")
                .map((e, idx) => (
                  <tr>
                    {state.multiValueData.cellIndex === 4 ||
                    state.multiValueData.cellIndex === 19 ? (
                      <td>
                        <TextField
                          value={e.split("|")[0].split("#")[0]}
                          onChange={(e) => {
                            let ctr0 =
                              state.readingRows[state.multiValueData.rowIndex][
                                state.multiValueData.cellIndex
                              ].split("||");
                            let ctr1 = ctr0[idx].split("|");
                            let ctr2 = ctr1[0].split("#");
                            ctr2[0] = e.target.value;
                            ctr1[0] =
                              e.target.value !== "" ? ctr2.join("#") : "";
                            ctr0[idx] = ctr1.join("|");
                            updateCellValue(
                              state.multiValueData.rowIndex,
                              state.multiValueData.cellIndex,
                              ctr0.join("||")
                            );
                          }}
                        />
                      </td>
                    ) : (
                      ""
                    )}
                    {state.multiValueData.cellIndex === 4 ||
                    state.multiValueData.cellIndex === 19 ? (
                      <td>{" - "}</td>
                    ) : (
                      ""
                    )}
                    <td>
                      <TextField
                        value={
                          state.multiValueData.cellIndex === 4 ||
                          state.multiValueData.cellIndex === 19
                            ? e.split("|")[1]
                              ? e.split("|")[1].split("#")[0]
                              : ""
                            : e.split("#")[0]
                        }
                        onChange={(e) => {
                          let ctr0 =
                            state.readingRows[state.multiValueData.rowIndex][
                              state.multiValueData.cellIndex
                            ].split("||");
                          let ctr1 = ctr0[idx].split("|");
                          if (
                            state.multiValueData.cellIndex === 4 ||
                            state.multiValueData.cellIndex === 19
                          ) {
                            let ctr2 = ctr1[1] ? ctr1[1].split("#") : [""];
                            ctr2[0] = e.target.value;
                            ctr1[1] = ctr2.join("#");
                            ctr0[idx] = ctr1.join("|");
                          } else {
                            let ctr2 = ctr1[0].split("#");
                            ctr2[0] = e.target.value;
                            ctr1[0] = ctr2.join("#");
                            ctr0[idx] = ctr1;
                          }
                          updateCellValue(
                            state.multiValueData.rowIndex,
                            state.multiValueData.cellIndex,
                            ctr0.join("||")
                          );
                        }}
                        disabled={
                          state.multiValueData.cellIndex === 4 ||
                          state.multiValueData.cellIndex === 19
                            ? e.split("|")[0].split("#")[0] === ""
                            : false
                        }
                      />
                    </td>
                    <td>
                      <select
                        styles={{ width: "100%" }}
                        onChange={(e) => {
                          let ctr0 =
                            state.readingRows[state.multiValueData.rowIndex][
                              state.multiValueData.cellIndex
                            ].split("||");
                          let ctr1 = ctr0[idx].split("|");
                          let ctr2 = "";
                          if (ctr1[0].length > 0) {
                            ctr2 = ctr1[0].split("#");
                            ctr2 = [ctr2[0], e.target.value];
                            ctr1[0] = ctr2.join("#");
                          }
                          if (ctr1.length > 1) {
                            ctr2 = ctr1[1].split("#");
                            ctr2 = [ctr2[0], e.target.value];
                            ctr1[1] = ctr2.join("#");
                          }
                          ctr0[idx] = ctr1.join("|");
                          updateCellValue(
                            state.multiValueData.rowIndex,
                            state.multiValueData.cellIndex,
                            ctr0.join("||")
                          );
                        }}
                        value={
                          e.split("|")[0].split("#")[1]
                            ? e.split("|")[0].split("#")[1]
                            : ""
                        }
                        disabled={
                          state.multiValueData.cellIndex === 4 ||
                          state.multiValueData.cellIndex === 19
                            ? e.split("|")[0].split("#")[0] === "" ||
                              !e.split("|")[1]
                            : e === ""
                        }
                      >
                        {state.unitArray.map((option) => (
                          <option value={option.symbol}>{option.symbol}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {state.readingRows[state.multiValueData.rowIndex][
                        state.multiValueData.cellIndex
                      ].split("||").length > 1
                        ? showComponent("delete") && (
                            <DeleteIcon
                              style={{ color: "#dc3545" }}
                              onClick={(e) => {
                                let tmp = state.readingRows[
                                  state.multiValueData.rowIndex
                                ][state.multiValueData.cellIndex]
                                  .split("||")
                                  .filter((_, i) => i !== idx);
                                updateCellValue(
                                  state.multiValueData.rowIndex,
                                  state.multiValueData.cellIndex,
                                  tmp.join("||")
                                );
                              }}
                            />
                          )
                        : ""}
                    </td>
                  </tr>
                ))}
              <tr>
                <td
                  colSpan={
                    state.multiValueData.cellIndex === 4 ||
                    state.multiValueData.cellIndex === 19
                      ? 4
                      : 2
                  }
                >
                  <Button
                    onClick={(e) => {
                      let ctr =
                        state.readingRows[state.multiValueData.rowIndex][
                          state.multiValueData.cellIndex
                        ];
                      ctr += "||";
                      updateCellValue(
                        state.multiValueData.rowIndex,
                        state.multiValueData.cellIndex,
                        ctr
                      );
                    }}
                  >
                    <b style={{ fontSize: "18px" }}>ADD</b>
                    <AddIcon />
                  </Button>
                </td>
              </tr>
            </table>
          </Box>
        </Modal>
      );
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    return axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => [[...[""].concat(res.data)]])
      .catch((err) =>
        errorFn("Something Went Wrong at (getUnitMasterArray) : ", err)
      );
  };

  const getSrfsList = () => {
    let url = BASE_URL;
    return axios
      .get(
        url +
          `xjoin?_join=srf.srfs,_j,client.clients&_on1=(srf.clientId,eq,client.id)&_fields=srf.serviceReqNumber,srf.jobNumber,srf.poNumber,srf.poDate,srf.receiptDate,srf.committedDeliveryDate,srf.dcNumber,srf.dcDate,srf.entryDate,srf.description,client.address,client.id,client.companyName&_where=(srf.id,eq, ${params.id})`
      )
      .then((res) => [res.data])
      .catch((err) => errorFn("Something Went Wrong at (getSrfsList) : ", err));
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    return axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        // setSrfInstruments(res.data);
        return [res.data];
      })
      .catch((err) =>
        errorFn("Something Went Wrong at (srfInstrumentsList) : ", err)
      );
  };

  const getIdentifiers = () => {
    let url = BASE_URL;
    return axios
      .get(
        url +
          `xjoin?_join=si.srfInstruments,_j,cert.certificates,_j,ds.datasheets&_on1=(si.id,eq,cert.id)&_on2=(si.id,eq,ds.id)&_fields=cert.certificateNumber,ds.JobNumber&_where=(si.srfsId,eq,${params.id})`
      )
      .then((res) => {
        return res.data.map((d) => [d.cert_certificateNumber, d.ds_JobNumber]);
      })
      .catch((err) =>
        errorFn("Something Went Wrong at (srfInstrumentsList) : ", err)
      );
  };

  const getExtraColumns = () => {
    // Terms and Condition
    let url = BASE_URL;
    return axios
      .get(
        url +
          "settings?_where=(keyName,eq,srfInstrumentsAdditionalColumns)&_fields=value"
      )
      .then((res) => {
        let tmp = [];
        if (res.data.length > 0) {
          // let new
          tmp = [
            state.readingColumns[0].concat(
              res.data[0].value.split(",").map((col) => {
                return {
                  field: col,
                  headerName: col,
                  editable: true,
                };
              })
            ),
          ];
          // setReadingColumns([tmp]);
          setState({ ...state, readingColumns: [tmp] });
        }
        console.log("getExtraColumns is completed!");
        // setFullColumnsLoaded(true);
        return [tmp];
      })
      .catch((err) =>
        errorFn("Something Went Wrong at (getExtraColumns) : ", err)
      );
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    return axios
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => [res.data])
      .catch((err) =>
        errorFn("Something Went Wrong at (instrumentsList) : ", err)
      );
  };

  useEffect(() => {
    getData();
  }, []);

  const submitDescriptionRows2 = async (readingRows, extraColumns) => {
    let rows = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        // srfsId: parseInt(params.id),
        srfInstrumentId: parseInt(
          `${state.readingRows[i][1]}`.split(",")[0].trim()
        ),
        ranges: readingRows[i][4],
        lc: readingRows[i][5],
        serialNumber: readingRows[i][3],
        make: readingRows[i][6],
        model: readingRows[i][7],
        DUCID: readingRows[i][2],
        ConOfDuc: readingRows[i][8],
        calFrequency: readingRows[i][9],
        accuracy: readingRows[i][10],
        calibrationType: readingRows[i][11],
        location: readingRows[i][12],
        locationOfInstrument: readingRows[i][13],
        calMethod: readingRows[i][14],
        // labCapabilities: readingRows[i][16],
        id: Number(readingRows[i][0]),
        extraColumns: JSON.stringify(extraColumns[i]),
        serviceReqNumber: state.serviceReqNumber,
        dcNumber: state.dcNumber,
        dcDate: moment(state.dcDate).format("YYYY-MM-DD"),
        poNumber: state.poNumber,
        poDate: moment(state.poDate).format("YYYY-MM-DD"),
      });
    }

    let cRows = JSON.parse(JSON.stringify(rows));
    cRows.map((e) => delete e.calFrequency);

    // console.log(rows);
    Promise.all([
      (async () => {
        await Promise.all(
          rows.map(async (row, id) => {
            await axios.patch(BASE_URL + `datasheets/${row.id}`, row);
            await axios.patch(BASE_URL + `certificates/${row.id}`, cRows[id]);
          })
        );
      })(),
    ])
      .then(async (res) => {
        let identifiers = await getIdentifiers();
        let changeLog = [];
        readingRows.map((r) => {
          changeLog.push([r[0], checkReadingChange(r)]);
        });
        changeLog = changeLog.filter((e) => e[1] !== "");
        console.log(
          "Certificate and Datasheet Reading Table changes : ",
          changeLog
        );
        changeLog.map(async (r, i) => {
          await generateAudit(
            "datasheets",
            identifiers[i][1],
            user[0],
            moment().format("yyyy-MM-DD HH:mm:ss"),
            "Updated",
            r[1],
            state.clientId
          );
          await generateAudit(
            "certificates",
            identifiers[i][0],
            user[0],
            moment().format("yyyy-MM-DD HH:mm:ss"),
            "Updated",
            r[1],
            state.clientId
          );
        });
        toast.success("SRF Updated!");
        props.setLoader(false);
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        console.log("error : ", err);
        toast.error("error in submitDescriptionRows2: ", err);
        props.setLoader(false);
      });
  };

  const srfFields = [
    ["receiptDate"],
    ["dcNumber"],
    ["dcDate", "date"],
    ["entryDate", "date"],
    ["committedDeliveryDate", "date"],
    ["serviceReqNumber"],
    ["jobNumber"],
    ["description"],
    ["address"],
    ["poNumber"],
    ["poDate"],
  ];

  const parseFormat = (val, format) => {
    switch (format) {
      case "date":
        return moment(val).format("YYYY-MM-DD");
      default:
        return val;
    }
  };

  const checkChange = (field, type) => {
    if (state.oldData[field] !== state[field]) {
      return `${field} : ${parseFormat(
        state.oldData[(field, type)]
      )} >> ${parseFormat(state[field], type)}`;
    }
    return "";
  };

  const checkReadingChange = (row, insertId) => {
    let change = row
      .map((e, i) =>
        state.oldData.readingRows[row[0]][i] !== e
          ? `${state.readingColumns[0][i].headerName} : ${
              state.oldData.readingRows[row[0]][i]
            } >> ${e}`
          : false
      )
      .filter((e) => e)
      .join(" , ")
      .replaceAll("#", " ")
      .replaceAll("_rh_", "")
      .replaceAll("_unit_", "");
    return change.length > 0 ? change : insertId ? "Empty" : "";
  };

  const handleSubmit = async (event) => {
    let rows = [];
    let extraColumns = [];

    for (let i = 0; i < state.readingRows.length; i++) {
      let extraCols = {};
      // console.log("reading row : ", state.readingRows[i]);
      if (state.readingColumns[0].length > fixedColumns.length) {
        for (
          let j = fixedColumns.length;
          j < state.readingColumns[0].length;
          j++
        ) {
          extraCols[`${state.readingColumns[0][j].field}`] =
            state.readingRows[i][j];
        }
      }
      let cols = {
        srfsId: parseInt(params.id),
        instrumentId: parseInt(
          `${state.readingRows[i][1]}`.split(",")[0].trim()
        ),
        ranges: state.readingRows[i][4],
        lc: state.readingRows[i][5],
        serialNumber: state.readingRows[i][3],
        make: state.readingRows[i][6],
        model: state.readingRows[i][7],
        DUCID: state.readingRows[i][2],
        ConOfDuc: state.readingRows[i][8],
        calFrequency: state.readingRows[i][9],
        accuracy: state.readingRows[i][10],
        calibrationType: state.readingRows[i][11],
        location: state.readingRows[i][12],
        locationOfInstrument: state.readingRows[i][13],
        calPoint: state.readingRows[i][14],
        calMethod: state.readingRows[i][15],
        labCapabilities: state.readingRows[i][16],
        id: Number(state.readingRows[i][0]),
      };

      if (Object.keys(extraCols).length) {
        cols["extraColumns"] = JSON.stringify(extraCols);
      }

      extraColumns.push(extraCols);

      rows.push(cols);
    }

    let row = {
      receiptDate: moment(state.receiptDate).format("YYYY-MM-DD"),
      dcNumber: state.dcNumber,
      dcDate: moment(state.dcDate).format("YYYY-MM-DD"),
      entryDate: moment(state.entryDate).format("YYYY-MM-DD"),
      committedDeliveryDate: moment(state.committedDeliveryDate).format(
        "YYYY-MM-DD"
      ),
      serviceReqNumber: state.serviceReqNumber,
      jobNumber: state.jobNumber,
      description: state.description,
      address: state.address,
      poNumber: state.poNumber,
      poDate: moment(state.poDate).format("YYYY-MM-DD"),
    };

    Promise.all([
      (async () => {
        await axios.patch(BASE_URL + `srfs/${params.id}`, row);
        await Promise.all([
          rows.map(
            async (r) =>
              await axios.patch(BASE_URL + `srfInstruments/${r.id}`, r)
          ),
        ]);
      })(),
    ])
      .then(async (res) => {
        let changeLog = [];
        srfFields.map((s) => changeLog.push(checkChange(s[0], s[1])));
        changeLog = changeLog.filter((e) => e !== "").join(" , ");
        console.log("SRF changes : ", changeLog);

        let changeLog2 = [];
        state.readingRows.map((r) => {
          changeLog2.push([r[0], checkReadingChange(r)]);
        });
        changeLog2 = changeLog2.filter((e) => e[1] !== "");
        console.log("change log 2 : ", changeLog2);

        if (changeLog !== "")
          generateAudit(
            "srfs",
            state.serviceReqNumber,
            user[0],
            moment().format("yyyy-MM-DD HH:mm:ss"),
            "Updated",
            changeLog,
            state.clientId
          );
        changeLog2.map(
          async (r, i) =>
            await generateAudit(
              "srfInstruments",
              r[0],
              user[0],
              moment().format("yyyy-MM-DD HH:mm:ss"),
              "Updated",
              r[1],
              state.clientId
            )
        );
        await submitDescriptionRows2(state.readingRows, extraColumns);
      })
      .catch((err) => {
        console.log("error : ", err);
        toast.error("error in handleSubmit : ", err);
        props.setLoader(false);
      });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...state.readingRows];
    newRows[rowIndex][colIndex] = value;
    setState({ ...state, readingRows: [...newRows] });
  };

  const addRangeRow = () => {
    let newReadingRows = [...state.readingRows];
    newReadingRows.push(Array(state.readingColumns[0].length).fill("")); //.map(c => "").filter((s, i) => i < ))
    // console.log("this is new reading row : ", state.readingRows, newReadingRows)
    setState({ ...state, readingRows: [...newReadingRows], copyQt: 1 });
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...state.readingRows];
    newReadingRows.splice(rowIndex, 1);
    let tmp = [...state.copyQt];
    tmp.splice(rowIndex, 1);
    setState({ ...state, readingRows: [...newReadingRows], copyQt: [...tmp] });
  };

  var refresh = () => {
    window.location.reload(false);
    navigate("/srf");
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Update SRF
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            fullWidth
            disabled
            value={state.clientName}
            variant="outlined"
            onChange={(e) => setState({ ...state, clientName: e.target.value })}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Receipt Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={state.receiptDate ? new Date(state.receiptDate) : ""}
              onChange={(newValue) =>
                setState({ ...state, receiptDate: newValue })
              }
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No. *"
            size="small"
            fullWidth
            value={state.dcNumber}
            variant="outlined"
            onChange={(e) => setState({ ...state, dcNumber: e.target.value })}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="DC Dt./RGP Dt. *"
              value={state.dcDate ? new Date(state.dcDate) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setState({ ...state, dcDate: newValue })}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Entry Date *"
              value={state.entryDate ? new Date(state.entryDate) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) =>
                setState({ ...state, entryDate: newValue })
              }
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Committed delivery date *"
              value={
                state.committedDeliveryDate
                  ? new Date(state.committedDeliveryDate)
                  : ""
              }
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) =>
                setState({ ...state, committedDeliveryDate: newValue })
              }
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            value={state.serviceReqNumber}
            variant="outlined"
            onChange={(e) =>
              setState({ ...state, serviceReqNumber: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Job number"
            size="small"
            fullWidth
            value={state.jobNumber}
            variant="outlined"
            onChange={(e) => setState({ ...state, jobNumber: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            fullWidth
            value={state.address}
            disabled
            variant="outlined"
            onChange={(e) => setState({ ...state, address: e.target.value })}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            fullWidth
            value={state.poNumber}
            variant="outlined"
            onChange={(e) => setState({ ...state, poNumber: e.target.value })}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="PO Date *"
              value={state.poDate ? new Date(state.poDate) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setState({ ...state, poDate: newValue })}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ "margin-bottom": "0px", "margin-top": "12px" }}>
        Description as below
      </h4>
      {showComponent("add_in") && (
        <Button
          style={{ float: "left" }}
          onClick={(e) => {
            addRangeRow(0);
          }}
        >
          <b style={{ fontSize: "18px" }}>ADD</b>
          <AddIcon />
        </Button>
      )}
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {state.readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <TextField
                        style={{
                          width: "75px",
                          marginRight: "10px",
                        }}
                        type="number"
                        // inputProps={{ min: 1, max: 75 }}
                        InputProps={{ inputProps: { min: 1, max: 75 } }}
                        size="small"
                        value={state.copyQt[index]}
                        onChange={(e) => {
                          let tmp = [...state.copyQt];
                          tmp[index] = Number(e.target.value);
                          // setCopyQt(tmp);
                          setState({ ...state, copyQt: tmp });
                        }}
                      ></TextField>
                      <Tooltip title="Copy as New Row" placement="top-start">
                        <ContentCopyIcon
                          style={{ color: "#1976d2" }}
                          onClick={(e) => {
                            let tmp = [...state.readingRows];
                            let tmp2 = [];
                            tmp.splice(
                              index + 1,
                              0,
                              ...Array(state.copyQt[index])
                                .fill(0)
                                .map((e) => [...state.readingRows[index]])
                            );
                            tmp2 = [...state.copyQt];
                            tmp2.splice(
                              index + 1,
                              0,
                              ...Array(state.copyQt[index])
                                .fill(0)
                                .map((e) => 1)
                            );
                            tmp2[index] = 1;
                            setState({
                              ...state,
                              readingRows: [...tmp],
                              copyQt: tmp2,
                            });
                          }}
                        />
                      </Tooltip>
                      {showComponent("delete") && (
                        <Tooltip title="Delete Row" placement="top-start">
                          <DeleteIcon
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              deleteReadingRow(0, index);
                            }}
                          />
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 1 &&
                      cellIndex != 4 &&
                      cellIndex != 5 &&
                      cellIndex != 10 &&
                      cellIndex != 9 &&
                      (cellIndex != 11) &
                        (cellIndex != 0) &
                        (cellIndex != 12) &&
                      cellIndex != 14 &&
                      cellIndex != 15
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            style={{ width: "200px" }}
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell>
                          <TextField
                            style={{ width: "200px" }}
                            size="small"
                            value={cell}
                            disabled
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setState({
                                ...state,
                                multiValueData: {
                                  rowIndex: index,
                                  cellIndex: cellIndex,
                                },
                                multiValueModalOpen: !state.multiValueModalOpen,
                              });
                            }}
                            style={{
                              backgroundColor:
                                state.readingRows[index][cellIndex] &&
                                state.readingRows[index][cellIndex].length > 0
                                  ? "#d9fdd3"
                                  : "",
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setState({
                                ...state,
                                multiValueData: {
                                  rowIndex: index,
                                  cellIndex: cellIndex,
                                },
                                multiValueModalOpen: !state.multiValueModalOpen,
                              });
                            }}
                            style={{
                              backgroundColor:
                                state.readingRows[index][cellIndex] &&
                                state.readingRows[index][cellIndex].length > 0
                                  ? "#d9fdd3"
                                  : "",
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 10) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setState({
                                ...state,
                                multiValueData: {
                                  rowIndex: index,
                                  cellIndex: cellIndex,
                                },
                                multiValueModalOpen: !state.multiValueModalOpen,
                              });
                            }}
                            style={{
                              backgroundColor:
                                state.readingRows[index][cellIndex] &&
                                state.readingRows[index][cellIndex].length > 0
                                  ? "#d9fdd3"
                                  : "",
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 9) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{ width: "200px" }}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "3 Months" },
                                { id: 2, label: "6 Months" },
                                { id: 3, label: "12 Months" },
                                { id: 6, label: "18 Months" },
                                { id: 5, label: "24 Months" },
                              ]}
                              value={cell}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 11) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{ width: "200px" }}
                              defaultValue={
                                [
                                  {
                                    id: 1,
                                    label: "NABL",
                                  },
                                  {
                                    id: 2,
                                    label: "NON NABL",
                                  },
                                  {
                                    id: 3,
                                    label: "OFF RECORD",
                                  },
                                ][cell]
                              }
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "NABL",
                                },
                                {
                                  id: 2,
                                  label: "NON NABL",
                                },
                                {
                                  id: 3,
                                  label: "OFF RECORD",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "NABL") {
                                  updateCellValue(index, cellIndex, 0);
                                } else if (newInputValue === "NON NABL") {
                                  updateCellValue(index, cellIndex, 1);
                                } else {
                                  updateCellValue(index, cellIndex, 2);
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 12) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{ width: "200px" }}
                              defaultValue={
                                {
                                  Onsite: {
                                    id: 1,
                                    label: "Onsite",
                                  },
                                  "In house": {
                                    id: 2,
                                    label: "In house",
                                  },
                                }[cell]
                              }
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "Onsite",
                                },
                                {
                                  id: 2,
                                  label: "In house",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Onsite") {
                                  updateCellValue(index, cellIndex, "Onsite");
                                } else {
                                  updateCellValue(index, cellIndex, "In house");
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 14) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{ width: "200px" }}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "As per proc" },
                                { id: 2, label: "As per customer" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                cell == "As per proc"
                                  ? "As per proc"
                                  : "As per customer"
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "As per proc") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per proc"
                                  );
                                  // changeCertificateStatus(3 + ":" + result.toString());
                                } else if (
                                  newInputValue === "As per customer"
                                ) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per customer"
                                  );
                                  // changeCertificateStatus(4 + ":" + result.toString());
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 15) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{ width: "200px" }}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "Direct" },
                                { id: 2, label: "Comparison" },
                                { id: 3, label: "Direct/Comparison" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                cell == "Direct"
                                  ? "Direct"
                                  : cell == "Comparison"
                                  ? "Comparison"
                                  : "Direct/Comparison"
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Direct") {
                                  updateCellValue(index, cellIndex, "Direct");
                                } else if (newInputValue === "Comparison") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Comparison"
                                  );
                                } else {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Direct/Comparison"
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    // if (cellIndex == 19) {
                    //   return (
                    //     <TableCell>
                    //       <Button
                    //         variant="outlined"
                    //         size="small"
                    //         onClick={() => {
                    //           setState({
                    //             ...state,
                    //             multiValueData: {
                    //               rowIndex: index,
                    //               cellIndex: cellIndex,
                    //             },
                    //             multiValueModalOpen: !state.multiValueModalOpen,
                    //           });
                    //         }}
                    //         style={{
                    //           backgroundColor:
                    //             state.readingRows[index][cellIndex] &&
                    //             state.readingRows[index][cellIndex].length > 0
                    //               ? "#d9fdd3"
                    //               : "",
                    //         }}
                    //       >
                    //         Add
                    //       </Button>
                    //     </TableCell>
                    //   );
                    // }
                  })}
                  <TableCell>
                    {showComponent("delete") && (
                      <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(0, index);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            handleSubmit();
          }}
        >
          Update
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      {renderMultivalueModal()}
      <ToastContainer />
    </Paper>
  );
}
