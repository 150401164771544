import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
  Paper,
  withStyles,
  Autocomplete,
  Toolbar,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import TextEditor from "../../utils/components/textEditor";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SignatureCanvas from "../../utils/components/SignatureCanvas";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import html2pdf from "html2pdf.js";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { toPng } from "html-to-image";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const styles = {
  headerCell: {
    fontWeight: "bold",
  },
};
const initialCriteriaMap = {
  0: "evaluation_easeOfEnquiry",
  1: "evaluation_rating",
  2: "evaluation_calibrationCertificate",
  3: "evaluation_turnAround",
  4: "evaluation_handlingOfUUC",
  5: "evaluation_costOfCalibration",
  6: "evaluation_satisfaction",
  7: "evaluation_ratings",
  8: "preferredDate",
};

const EditFeedback = ({ classes }) => {
  const Description = `<h3><br></h3><h3><strong style="background-color: transparent;">﻿Dear Sir / Mam,</strong></h3><p><br></p><h3><span style="background-color: transparent;">We thank you for the business with</span><strong style="background-color: transparent;"> Jayasree Reva Phoenix Metrology Pvt. Ltd. – Calibration | Inspection | Testing | Training | Services.</strong><span style="background-color: transparent;"> We believe in Continual Process Improvement &amp; are committed to achieving 100% Customer Satisfaction. We take this opportunity to ask for your assistance in evaluating our performance of</span></h3><h3><span style="background-color: transparent;">Calibration / Testing / Services provided to you, to keep up in pace with our learning goals. We appreciate your time and value your association. </span></h3><p><br></p><h3><span style="background-color: transparent;">Regards,</span></h3><p><br></p><h3><strong style="background-color: transparent;">Manikandan Venkatesan | Managing Director | Jayasree Reva Phoenix Metrology Pvt. Ltd.</strong></h3><h3><span style="background-color: transparent;">To register all of your Appreciation - Queries - Complaints - Suggestions - Escalation - Please E-mail me at</span></h3><h3><a href="www.info@revaphoenix.com" rel="noopener noreferrer" target="_blank" style="background-color: transparent;">info@revaphoenix.com</a></h3><p><br></p><p><br></p><p><br></p>`;
  var [clientArray, setClientArray] = React.useState([]);
  const [clientsArrays, setClientsArrays] = React.useState([]);
  const [editorValue, setEditorValue] = React.useState(Description);
  const [criteriaMap, setCriteriaMap] = React.useState(initialCriteriaMap);
  const userName = localStorage.getItem("id");
  const params = useParams();
  const [users, setUsers] = React.useState([]);
  const [selectedIndexes, setSelectedIndexes] = React.useState({});
  const printComponentRef = React.useRef();
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [complaintObject, createComplaintObject] = React.useState({
    customerName: null,
    address: null,
    contactPerson: null,
    mobileNumber: null,
    csrfNumber: null,
    suggestion1: ["", "", ""],
    suggestion2: ["", "", ""],
    createdBy: userName || null,
    approvedby: userName || null,
    date: null,
    preparedBy: userName || null,
    preparedBySign: null,
    approvedBySign: null,
  });
  // console.log("selectedIndexes", selectedIndexes);

  const updateComplaintObject = (key, index, value) => {
    if (key === "suggestion1" || key === "suggestion2") {
      createComplaintObject((prevComplaintObject) => ({
        ...prevComplaintObject,
        [key]: prevComplaintObject[key].map((item, i) =>
          i === index ? value : item
        ),
      }));
    } else {
      let newComplaintObject = {
        ...complaintObject,
      };
      newComplaintObject[key] = index;
      createComplaintObject(newComplaintObject);
    }
  };
  function getUsers() {
    const data = {
      query: "SELECT * FROM users",
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }
  let userObject = users.filter(
    (e) => e.id === complaintObject?.preparedBy
  );
  const createdByValue = complaintObject?.preparedBy ? userObject[0]?.userName : null;
  const handleSuggestion1Change = (index, value) => {
    console.log(
      "handleSuggestion1Change called with index:",
      index,
      "and value:",
      value
    );
    updateComplaintObject("suggestion1", index, value);
  };

  const handleSuggestion2Change = (index, value) => {
    console.log(
      "handleSuggestion2Change called with index:",
      index,
      "and value:",
      value
    );
    updateComplaintObject("suggestion2", index, value);
  };

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let clientData = res.data;

        var pushCArray = [];
        var clientsArrays = [];
        for (let i = 0; i < clientData.length; i++) {
          if (clientData[i].companyName) {
            pushCArray.push({
              label: clientData[i].id + ", " + clientData[i].companyName,
            });
          }
          clientsArrays.push({
            id: clientData[i].id,
            companyName: clientData[i].companyName,
            address: clientData[i].address,
            contact: clientData[i].contact,
            gstNumber: clientData[i].gstNumber,
          });
          setClientArray(pushCArray);
        }
        setClientsArrays(clientsArrays);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const handleSubmit = (event) => {
    let url = BASE_URL;
    let Payload = {
      customerName: complaintObject?.customerName,
      address: complaintObject?.address,
      contactPerson: complaintObject?.contactPerson,
      mobileNumber: complaintObject?.mobileNumber,
      csrfNumber: complaintObject?.csrfNumber,
      evaluation_easeOfEnquiry: selectedIndexes?.evaluation_easeOfEnquiry,
      evaluation_rating: selectedIndexes?.evaluation_rating,
      evaluation_calibrationCertificate:
        selectedIndexes?.evaluation_calibrationCertificate,
      evaluation_turnAround: selectedIndexes?.evaluation_turnAround,
      evaluation_handlingOfUUC: selectedIndexes?.evaluation_handlingOfUUC,
      evaluation_costOfCalibration:
        selectedIndexes?.evaluation_costOfCalibration,
      evaluation_satisfaction: selectedIndexes?.evaluation_satisfaction,
      evaluation_ratings: selectedIndexes?.evaluation_ratings,
      // preferredDate: selectedIndexes?.preferredDate,
      suggestion1: JSON.stringify(complaintObject?.suggestion1),
      suggestion2: JSON.stringify(complaintObject?.suggestion2),
      createdBy: complaintObject?.createdBy,
      approvedby: complaintObject?.approvedby,
      date: moment(complaintObject?.date).format("YYYY-MM-DD"),
      preparedBy: complaintObject?.preparedBy,
      preparedBySign: complaintObject?.preparedBySign,
      approvedBySign: complaintObject?.approvedBySign,
    };
    if (params.id) {
      axios
        .patch(url + `customerFeedbackForm/${params.id}`, Payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Customer Feedback edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axios
        .post(url + "customerFeedbackForm", Payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Customer Feedback created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const fetchComplaintDetails = (inpt) => {
    let url = BASE_URL;

    axios
      .get(url + `customerFeedbackForm/${params.id}`)
      .then((res) => {
        const complaintData = res.data[0];

        const companyName = clientArray.find(
          (e) => e.label.split(",")[0] == complaintData?.customerId
        );
        console.log("complaintData23456:", complaintData?.customerName);

        const newData1 = {
          ...selectedIndexes,
          evaluation_easeOfEnquiry: complaintData?.evaluation_easeOfEnquiry,
          evaluation_rating: complaintData?.evaluation_rating,
          evaluation_calibrationCertificate:
            complaintData?.evaluation_calibrationCertificate,
          evaluation_turnAround: complaintData?.evaluation_turnAround,
          evaluation_handlingOfUUC: complaintData?.evaluation_handlingOfUUC,
          evaluation_costOfCalibration:
            complaintData?.evaluation_costOfCalibration,
          evaluation_satisfaction: complaintData?.evaluation_satisfaction,
          evaluation_ratings: complaintData?.evaluation_ratings,
          // preferredDate: complaintData?.preferredDate
        };

        const newData123 = {
          ...complaintObject,
          customerName: complaintData?.customerName,
          address: complaintData?.address,
          contactPerson: complaintData?.contactPerson,
          mobileNumber: complaintData?.mobileNumber,
          csrfNumber: complaintData?.csrfNumber,
          suggestion1: complaintData?.suggestion1
            ? JSON.parse(complaintData?.suggestion1)
            : "",
          suggestion2: complaintData?.suggestion2
            ? JSON.parse(complaintData?.suggestion2)
            : "",
          createdBy: complaintData?.createdBy,
          approvedby: complaintData?.approvedby,
          date: complaintData?.date,
          preparedBy: complaintData?.preparedBy,
          preparedBySign: complaintData?.preparedBySign,
          approvedBySign: complaintData?.approvedBySign,
        };

        complaintData && createComplaintObject(newData123);
        complaintData && setSelectedIndexes(newData1);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const FeedbackTable = () => {
    const handleCheckboxChange = (rowIndex, index) => {
      setSelectedIndexes((prevSelectedIndexes) => ({
        ...prevSelectedIndexes,
        [criteriaMap[rowIndex]]: index,
      }));
    };

    const rows = [
      {
        criterion: "Ease of Enquiry – Communication - Quote",
        description:
          "Queries related to Calibration / Testing / Services - Technical & Commercial aspects answered in a timely manner.",
      },
      {
        criterion: "Quality rating of our Calibration / Testing Services",
        description:
          "Level of your satisfaction with the Quality of our Calibration / Testing and the Quality of results.",
      },
      {
        criterion: "Calibration Certificate / Testing Reports",
        description:
          "Level of your satisfaction with the Quality of Calibration Certificates / Test Reports – Traceability Chain and related records.",
      },
      {
        criterion: "Turn-around Time – On-time delivery ",
        description:
          "Level of Satisfaction with the on-time adherence - Schedule of your request for Calibration / Testing – On-time delivery as per commitment",
      },
      {
        criterion: "Handling of UUC – Material",
        description:
          "Level of your satisfaction with the handling, packaging and transportation of your UUC - Material.",
      },
      {
        criterion: "Cost of our Calibration / Testing",
        description:
          "Level of your satisfaction with the Cost of Calibration / Testing – Value for your money",
      },
      {
        criterion:
          "Overall Satisfaction rating with Jayasree Reva Phoenix Metrology Pvt. Ltd. ",
        description:
          "Level of your overall satisfaction in doing business  with Jayasree Reva Phoenix Metrology Pvt. Ltd.",
      },
      {
        criterion: "Rate our Lab & Facilities - Infrastructure",
        description:
          "Have you visited our Laboratory? Your rating of our Infrastructure – Facility – Resource",
      },
      {
        criterion: "If you have not visited our Laboratory, we welcome you!",
        description:
          "Please let us know and we will do all the needful arrangements for your visit. Your preferred – Date & time:",
      },
    ];

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styles.headerCell}>
                Please evaluate our service
              </TableCell>
              <TableCell style={styles.headerCell}>Excellent 90-100%</TableCell>
              <TableCell style={styles.headerCell}>Very Good 80-90%</TableCell>
              <TableCell style={styles.headerCell}>Good 70-80%</TableCell>
              <TableCell style={styles.headerCell}>Average 50-70%</TableCell>
              <TableCell style={styles.headerCell}>Poor &lt;50%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell style={{ width: "50%" }}>
                  <b>{row.criterion}</b>
                  <br />
                  {row.description}
                </TableCell>
                {[1, 2, 3, 4, 5].map((value) => (
                  <TableCell key={value} style={{ width: "10%" }}>
                    {/* {rowIndex === rows.length - 1 ? (
                      <TextField type="datetime-local" style={{ width: '100%' }} />
                    ) : ( */}
                    <Checkbox
                      checked={selectedIndexes[criteriaMap[rowIndex]] === value}
                      onChange={() => handleCheckboxChange(rowIndex, value)}
                    />
                    {/* )} */}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo
  ) => {
    let htmlString = `    
    <div style="padding:30px;">
      <table style="width:100%;height: 30px;">
        <tr>
          ${
            ulrNumber
              ? `<td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Document No. </b> <br />
            <span style="font-size:19px;">${ulrNumber}</span>
          </td>`
              : ""
          }
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Issue No. | Date </b>
            <br>
            <span style="font-size:19px;">${certificateNumber}</span>
          </td>
                <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Revision No. | Date  </b>
            <br>
            <span style="font-size:19px;">${certificateNumber}</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Page Number</b>
            <br>
            <span style="font-size:19px;">${pageNo}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div  style="padding:30px;">
    <table style="width:100%;height: 30px;">
    <thead>
      <tr>
        <th style="font-weight: bold;">Prepared & Authorized By</th>
        <th style="font-weight: bold;">${createdByValue?.toUpperCase()}
        </th>
        <th style="font-weight: bold;">Date: ${moment(new Date()).format("DD/MM/YYYY")}</th>
      </tr>
    </thead>
  </table>
  
  <table style="margin-top: 10px;width:100%;height: 30px;">
    <thead>
      <tr>
        <th style="font-weight: bold;">Prepared & Issued By</th>
        <th style="font-weight: bold;">Director & Quality Manager</th>
        <th style="font-weight: bold;">Reviewed & Approved By</th>
        <th style="font-weight: bold;">Managing Director</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="font-weight: bold;">Signature</td>
        <td style="font-weight: bold;"> 
          <img src="${complaintObject?.preparedBySign}" alt="Signature" style="width: 100px; height: 50px; object-fit: cover;" />
        </td>
        <td style="font-weight: bold;">Signature</td>
        <td style="font-weight: bold;">
          <img src="${complaintObject?.approvedBySign}" alt="Signature" style="width: 100px; height: 50px; object-fit: cover;" />
        </td>
      </tr>
      <tr>
        <td style="font-weight: bold;">Date</td>
        <td style="font-weight: bold;">${moment(new Date()).format("DD/MM/YYYY")}</td>
        <td style="font-weight: bold;">Date</td>
        <td style="font-weight: bold;">${moment(new Date()).format("DD/MM/YYYY")}</td>
      </tr>
    </tbody>
  </table>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [140, 1, 130, 1],
        filename: `AllCertificate.pdf`,
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy'],
        // },
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
  
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");
  
    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");
  
      let ulrNumber = "ABC123456";
      let certificateNumber = "361";
      let calibratedby = "BFI";
      let approvedby = "BFI123";
      let calibrationDate = "2023-09-21T03:03:29.000Z";
  
      let image = await generateDynamicHeader(ulrNumber, certificateNumber, i);
      pdf.addImage(image, 0, 70, 595, 80);
  
      // image = await generateDynamicFooter(
      //   calibratedby,
      //   approvedby,
      //   calibrationDate
      // );
      // pdf.addImage(image, 0, 675, 595, 100);
  
      if (letterHead) {
        let image = await generateLetterHeadHeader();
  
        pdf.addImage(image, 0, 0, 595, 70);
  
        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 702, 595, 140);
      }
    }
  
    pdf.save();
  };


  useEffect(() => {
    getClientIdList();
    getUsers();
    if (params.id) fetchComplaintDetails();
  }, []);

  return (
    <div>
        {params.print ? (<Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>) : null}
                  
    <div
    ref={printComponentRef}
    >
       <div style={{ width: "100%" }} id="header-section"></div>
       <Grid md={12} className="font-link" align="center">
            <h5 style={{ color: "#000066", margin: "10px 0px" }}>
              <b>CUSTOMER FEEDBACK </b>
            </h5>
          </Grid>
      {/* <Typography variant="h6">Customer Feedback Form</Typography> */}

      <div
        style={{
          width: "95%",
          border: "1px solid black",
          textAlign: "center",
          margin: "10px auto",
          padding: "10px",
        }}
      >
        <table
          style={{ width: "100%", margin: "auto", fontSize: "18px" }}
          className="srftable"
        >
          <tr style={{ textAlign: "left" }}>
            <th
              style={{
                padding: "7px",
                borderBottom: "none",
                borderRight: "none",
                width: "40%",
              }}
            >
              Name of the Organization - Customer
            </th>
            <td
              style={{
                padding: "7px",
                width: "60%",
                borderBottom: "none",
              }}
            >
              {params.id ? (
                complaintObject?.customerName
              ) : (
                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  options={clientArray}
                  renderInput={(params) => <TextField {...params} />}
                  onInputChange={(event, newInputValue) => {
                    const [companyId, companyName] = newInputValue.split(",");
                    let Data = clientsArrays.find(
                      (client) => client.id === parseInt(companyId, 10)
                    );
                    createComplaintObject((prevComplaintObject) => ({
                      ...prevComplaintObject,
                      customerName: companyName,
                      address: Data?.address || "",
                      contactPerson: Data?.contact || "",
                      mobileNumber: Data?.contact || "",
                    }));
                  }}
                />
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th
              style={{
                padding: "7px",
                borderBottom: "none",
                borderRight: "none",
                width: "40%",
              }}
            >
              Address - Location
            </th>
            <td
              style={{
                padding: "7px",
                width: "60%",
                borderBottom: "none",
              }}
            >
              {complaintObject.address || ""}
            </td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th
              style={{
                padding: "7px",
                borderBottom: "none",
                borderRight: "none",
                width: "40%",
              }}
            >
              Contact Person - Position / Designation
            </th>
            <td
              style={{
                padding: "7px",
                width: "60%",
                borderBottom: "none",
              }}
            >
              {complaintObject?.contactPerson &&
                (() => {
                  const parsedData = JSON.parse(
                    complaintObject.contactPerson
                  )?.[0];

                  return (
                    <>
                      <h6 style={{ fontSize: "1em" }}>
                        Contact Person Name: &nbsp;{" "}
                        {parsedData?.contactPersonName}
                      </h6>
                    </>
                  );
                })()}
            </td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th
              style={{
                padding: "7px",
                borderBottom: "none",
                borderRight: "none",
                width: "40%",
              }}
            >
              Phone - Mobile Number | E-mail ID
            </th>
            <td
              style={{
                padding: "7px",
                width: "60%",
                borderBottom: "none",
              }}
            >
              {complaintObject?.mobileNumber &&
                (() => {
                  const parsedData = JSON.parse(
                    complaintObject.mobileNumber
                  )?.[0];

                  return (
                    <>
                      <h6 style={{ fontSize: "1em" }}>
                        Contact: &nbsp; {parsedData?.contact}
                      </h6>
                      <h6 style={{ fontSize: "1em" }}>
                        Email: &nbsp; {parsedData?.emailId}
                      </h6>
                    </>
                  );
                })()}
            </td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th
              style={{
                padding: "7px",
                borderRight: "none",
                width: "40%",
              }}
            >
              CSRF Number
            </th>
            <td
              style={{
                padding: "7px",
                width: "60%",
              }}
            >
              <input
                type="text"
                value={complaintObject?.csrfNumber || ""}
                onChange={(e) => {
                  updateComplaintObject("csrfNumber", e.target.value);
                }}
                style={{ width: "100%" }}
              />
            </td>
          </tr>
        </table>
        <Box
          sx={{
            textAlign: "left",
            fontSize: "18px",
            border: "1px solid black",
            borderTop: "none",
            padding: "20px",
          }}
        >
          {/* <TextEditor value={editorValue} onChange={handleEditorChange} /> */}

          <Typography variant="p">
            <b>Dear Sir / Mam</b>
          </Typography>
          <br />
          <Typography variant="p">
            We thank you for the business with{" "}
            <b>
              Jayasree Reva Phoenix Metrology Pvt. Ltd. – Calibration |
              Inspection | Testing | Training | Services.
            </b>
            We believe in Continual Process Improvement & are committed to
            achieve 100% Customer Satisfaction. We take this opportunity to ask
            for your assistance in evaluating our performance of Calibration /
            Testing / Services provided to you, to keep up in pace with our
            learning goals. We appreciate your time and value your association.
          </Typography>
          <br />
          <br />
          <Typography variant="p">
            <b>Regards</b>
          </Typography>
          <br />
          <Typography variant="p">
            <b>
              Manikandan Venkatesan | Managing Director | Jayasree Reva Phoenix
              Metrology Pvt. Ltd.
            </b>
          </Typography>
          <br />
          <Typography variant="p">
            <b>
              <i>
                To register all of your Appreciation - Queries - Complaints -
                Suggestions - Escalation - Please E-mail me at{" "}
                <span style={{ color: "blue" }}>info@revaphoenix.com</span>
              </i>
            </b>
          </Typography>
        </Box>
        <br />
        {FeedbackTable()}
        <br />
        <Box sx={{ textAlign: "left" }}>
          <b>
            Please help us with a reason or suggestion to improve our system, if
            your feedback is Poor, with a rating less than 50%
          </b>
          <br />
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{ width: "100%" }}
            value={complaintObject?.suggestion1?.[0] || ""}
            onChange={(e) => handleSuggestion1Change(0, e.target.value)}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{ width: "100%" }}
            value={complaintObject?.suggestion1?.[1] || ""}
            onChange={(e) => handleSuggestion1Change(1, e.target.value)}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{ width: "100%" }}
            value={complaintObject?.suggestion1?.[2] || ""}
            onChange={(e) => handleSuggestion1Change(2, e.target.value)}
          />
          <br />
          <br />
          <b>
            Any other Comments - Suggestions for our Organizational – Laboratory
            improvement:
          </b>
          <br />
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{ width: "100%" }}
            value={complaintObject?.suggestion2?.[0] || ""}
            onChange={(e) => handleSuggestion2Change(0, e.target.value)}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{ width: "100%" }}
            value={complaintObject?.suggestion2?.[1] || ""}
            onChange={(e) => handleSuggestion2Change(1, e.target.value)}
          />
          <TextField
            id="standard-basic"
            variant="standard"
            sx={{ width: "100%" }}
            value={complaintObject?.suggestion2?.[2] || ""}
            onChange={(e) => handleSuggestion2Change(2, e.target.value)}
          />
        </Box>

        <Table style={{ marginTop: 50 }}>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={3} align="center" width={150}>
                <h6
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Name:
                  <br />
                  <br />
                  Signature:
                  <br />
                  <br />
                  Date: &nbsp;{" "}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      // label="Challan Date *"
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      value={
                        complaintObject.date
                          ? new Date(complaintObject.date)
                          : ""
                      }
                      onChange={(newValue) => {
                        updateComplaintObject("date", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </h6>
              </TableCell>
              {params.print ? null : (
  <>
    <TableCell rowSpan={3} align="center" width={150}>
      <h6
        style={{
          textAlign: "left",
          padding: "5px",
          fontWeight: "bold",
        }}
      >
        PreparedBy Signature:
      </h6>
      <SignatureCanvas
        updateUrl={(url) => {
          createComplaintObject({
            ...complaintObject,
            preparedBySign: url,
          });
        }}
        url={complaintObject.preparedBySign}
      />
    </TableCell>
    <TableCell rowSpan={3} align="center" width={150}>
      <h6
        style={{
          textAlign: "left",
          padding: "5px",
          fontWeight: "bold",
        }}
      >
        ApprovedBy Signature:
      </h6>
      <SignatureCanvas
        updateUrl={(url) => {
          createComplaintObject({
            ...complaintObject,
            approvedBySign: url,
          });
        }}
        url={complaintObject.approvedBySign}
      />
    </TableCell>
  </>
)}

            </TableRow>
          </TableBody>
        </Table>

        {params.print ? null : (
          <Box>
            <Toolbar>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSubmit()}
              >
                <b>{params.id ? "Update" : "Save"}</b>
              </Button>
            </Toolbar>
          </Box>
        )}
      </div>
      <br />
    </div>
    </div>
  );
};

export default EditFeedback;
