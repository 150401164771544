//po

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const CreatePO_Acceptance = () => {
  const [quotationData, setquotationData] = useState([]);
  const [clientId, setSelectedClientId] = useState(null);
  const [quotationNumber, setQuotationNumber] = useState("");
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [quotationDate, setQuotationDate] = useState("");
  const [poFileUrl, setpoFileUrl] = useState("");
  const [poAacceptanceData, createPoAacceptance] = useState({});

  const updateSrfObject = (key, value) => {
    let newPoAacceptance = {
      ...poAacceptanceData,
    };
    newPoAacceptance[key] = value;
    createPoAacceptance({ ...newPoAacceptance });
  };

  const getquotationData = () => {
    let url = BASE_URL;
    axios
      .get(url + "quotation")
      .then((res) => {
        setquotationData(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    axios
      .post(
        BASE_URL+`fileUpload/fileUpload.php`,
        data
      )
      .then((res) => {
        if (res.status === 200) {
          setpoFileUrl(res.data);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something Went Wrong!");
      });
  };

  const addPoAcceptance = () => {
    const payload = {
      quotationDate: moment(quotationDate).format("YYYY-MM-DD"),
      quotationId: selectedQuotationId,
      poFileUrl: poFileUrl,
      poNumber: poAacceptanceData.poNumber,
      poDate: poAacceptanceData.poDate,
      amount: poAacceptanceData.amount,
    };
    // const payload1 = {
    //   clientId: clientId,
    //   poNumber: poAacceptanceData.poNumber,
    //   poDate: poAacceptanceData.poDate,
    // };
    let url = BASE_URL;
    axios
      .post(url + "poAcceptance", payload)
      .then((res) => {
        setTimeout(refresh, 500);
        toast("Po Acceptance created successfully !");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });

  };

  useEffect(() => {
    getquotationData();
  }, []);

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={quotationData}
            getOptionLabel={(option) => option.quotationNumber}
            renderInput={(params) => (
              <TextField {...params} label="Quotation Number *" />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                const selectedQuotation = quotationData.find(
                  (quotation) =>
                    quotation.quotationNumber === newValue.quotationNumber
                );
                if (selectedQuotation) {
                  setQuotationNumber(selectedQuotation.quotationNumber);
                  setQuotationDate(selectedQuotation.date);
                  setSelectedQuotationId(selectedQuotation.id);
                  //clientId
                  setSelectedClientId(selectedQuotation.clientId);
                } else {
                  setQuotationNumber("");
                  setQuotationDate("");
                  setSelectedQuotationId("");
                  setSelectedClientId("");
                }
              } else {
                setQuotationNumber("");
                setQuotationDate("");
                setSelectedQuotationId("");
                setSelectedClientId("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Quotation Date"
            size="small"
            disabled
            fullWidth
            variant="outlined"
            value={moment(quotationDate).format("YYYY-MM-DD") || ""}
            onChange={(e) =>
              setQuotationDate(moment(e.target.value).format("YYYY-MM-DD"))
            }
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Po Number *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("poNumber", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Po Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                poAacceptanceData.poDate
                  ? new Date(poAacceptanceData.poDate)
                  : ""
              }
              onChange={(newValue) => {
                updateSrfObject(
                  "poDate",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Amount *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("amount", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Input
            id="file-upload-input"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileUpload(e)}
          />
          <label htmlFor="file-upload-input">
            <Button variant="contained" component="span">
              Upload File
            </Button>
          </label>
          <Typography variant="body2" color="textSecondary">
            Select a file to upload
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Toolbar>
          <Button
            variant="contained"
            size="small"
            onClick={() => addPoAcceptance()}
          >
            <b>ADD</b>
          </Button>
        </Toolbar>
      </Box>
    </div>
  );
};

export default CreatePO_Acceptance;
