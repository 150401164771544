import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Global from "../image/sticker.jpeg";
import TableContainer from "@mui/material/TableContainer";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import "./certificateList.css";
import { countOfStickerAnalytics } from "../../utils/analytics/certificateAnalytics";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

export default function MultiQr() {
  const printComponentRef = React.useRef();
  const [allData, setAllData] = React.useState([]);
  const params = useParams();
  const fullHeader = true;
  const isHeaderAtLeft = true;
  let certificateIds = params.certificateId;
  const certificateIdsArray = certificateIds.split(",").map(Number);
  
  const [stikerHeight, setStikerHeight] = React.useState(null);  
  const [stikerWidth, setStikerWidth] = React.useState(null);  
  // const maxWidth = 210;
  // const maxHeight = 297;
  const sizeOptions = [
    { width: '30.7%', height: '16.8%', label: "Width: 75mm, Height: 50mm" },
    { width: '23.8%',height: '10.10%', label: "Width: 50mm, Height: 30mm" },
    { width: '40.4%',height: '18.5%', label: "Width: 85mm, Height: 55mm" }
  ];
  const [selectedSizes, setSelectedSizes] = React.useState({
    width: sizeOptions[0].width,
    height: sizeOptions[0].height,
  });
  const handleSizeChange = (event) => {
    let deviceWidth = document.getElementById("printStiker").clientWidth;
    console.log('deviceWidth',deviceWidth);
    const { value } = event.target;
    const [xMM, yMM] = value.split("-");
    
    const heightPixel = (parseFloat(yMM)  * deviceWidth) / 100;  
    const widthPixel = (parseFloat(xMM)  * deviceWidth) / 100;  
    console.log('heightPixel', heightPixel ,"widthPixel",widthPixel);
    console.log("xMM : ",xMM , "yMM : ",yMM);
    setStikerHeight(heightPixel);
    setStikerWidth(widthPixel);
    // setSelectedSizes({ width: xMM, height: yMM });
  };

  const getExpenseList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=certificate.certificates,_j,inst.instruments,_j,datasheet.datasheets,_j,certificateAnalytics.certificateAnalytics&_on1=(certificate.instrumentId,eq,inst.id)&_on2=(certificate.id,eq,datasheet.id)&_on3=(certificate.id,eq,certificateAnalytics.certificateId)&_fields=certificate.DUCID,certificate.calibrationDate,certificate.nextDueDate,inst.instrumentName,certificate.id,certificateAnalytics.stickerDownloadCounter,certificateAnalytics.certificateDownloadCounter,certificate.locationOfInstrument,certificate.certificateNumber,datasheet.calibratedby,datasheet.revisionNumber&_where=(certificate.id,in,${params.certificateId})`
      )
      .then((res) => {
        var splitIdArray = [];
        params.certificateId
          ? params.certificateId.split(",").map((ids) => {
              for (let i = 0; i < res.data.length; i++) {
                if (parseInt(ids) == res.data[i].certificate_id) {
                  splitIdArray.push(res.data[i]);
                }
              }
            })
          : console.log("no data");
        setAllData(splitIdArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const stickersPerPage = 6;
  const renderQrModal = () => {
    return allData.map((data, index) => {
      return (
        <div
          className="item"
          style={{
            height:"100%",
            pageBreakAfter: index % stickersPerPage === 5 ? "always" : "auto",
          }}
        >
          <div className="qrModalStyle">
              <img
                src={Global}
                style={{ width: "100%", height: "20%", objectFit: "contain" }}
                alt="rq-logo"
              ></img>             

            <div style={{ flex: "1", display: "flex" }}>
              <div style={{ width: "90%", margin: "5px auto" }}>
                <table
                  style={{
                    width: "100%",
                    height: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    <tr>
                      <td className="stickerTdM" style={{fontSize:"60%"}}>
                        <b>CERTIFICATE NO</b>{" "}
                      </td>
                      <td className="dataDivFromStickerM" style={{fontSize:"60%"}}>
                        <b>{`${data.certificate_certificateNumber}${
                          data.datasheet_revisionNumber > 0
                            ? `/AMD-${data.datasheet_revisionNumber}`
                            : ""
                        }`}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM" style={{fontSize:"60%"}}>
                        <b>INSTRUMENT</b>
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{
                          fontSize:
                            data.inst_instrumentName &&
                            data.inst_instrumentName.length > 30
                              ? "60%"
                              : "60%",
                        }}
                      >
                        <b>{data.inst_instrumentName}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM" style={{fontSize:"60%"}}>
                        <b>ID NO</b>
                      </td>
                      <td className="dataDivFromStickerM" style={{fontSize:"60%"}}>
                        <b>{data.certificate_DUCID}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM" style={{fontSize:"60%"}}>
                        <b>LOCATION</b>
                      </td>
                      <td className="dataDivFromStickerM" style={{fontSize:"60%"}}>
                        <b>{data.certificate_locationOfInstrument}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className=" bottomDiv"
                        style={{ display: "flex", border: "none" }}
                        colSpan={2}
                      >
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div className="bottomDivChildM" style={{fontSize:"60%"}}>
                            <b>CAL DATE</b>
                          </div>
                          <div className="bottomDivChildM" style={{fontSize:"60%"}}>
                            <b>
                              {data.certificate_calibrationDate
                                ? moment(
                                    data.certificate_calibrationDate
                                  ).format("DD-MM-YYYY")
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div className="bottomDivChildM" style={{fontSize:"60%"}}>
                            <b>DUE DATE</b>
                          </div>
                          <div className="bottomDivChildM" style={{fontSize:"60%"}}>
                            <b>
                              {data.certificate_nextDueDate
                                ? moment(data.certificate_nextDueDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div className="bottomDivChildM" style={{fontSize:"60%"}}>
                            <b>CAL BY</b>
                          </div>
                          <div className="bottomDivChildM" style={{fontSize:"60%"}}>
                            <b>
                              {data.datasheet_calibratedby
                                ? data.datasheet_calibratedby
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                            display: "flex",
                          }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/print/${data.certificate_id}`}
                            // size={50}
                            align="right"
                            style={{ margin: "auto",height:"70%" }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }}></td>
                      <td style={{ textAlign: "right", border: "none",fontSize:"70%" }}>
                        Download No.:
                        {data?.certificateAnalytics_stickerDownloadCounter +
                          1}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    getExpenseList();
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, p: 2 }}
      className="hiddenscroll"
    >
      <Typography variant="h5" component="h5" style={{ float: "center" }}>
        <b>
          <u>Multiple QR Code</u>
        </b>
      </Typography>
      <hr />
      <Box sx={{ width: "90%", m: "1.2rem auto" }}>
        <FormControl variant="standard" sx={{ minWidth: 230, float: "right" ,mb:"1rem" }}>
          <InputLabel id="demo-simple-select-label">Choose Your Sizes</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={`${selectedSizes.width}-${selectedSizes.height}`}
            onChange={handleSizeChange}
            label="Size"
          >
            {sizeOptions.map((option) => (
              <MenuItem
                key={`${option.width}-${option.height}`}
                value={`${option.width}-${option.height}`}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <div
      id="printStiker"
        className="row border-dark p-2 qrSticker"
        ref={printComponentRef}
        style={{ width: "100%", pageBreakAfter: "auto" }}
      >
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            gridGap: "20px",
            gridTemplateColumns: `repeat(2, ${(stikerWidth)}px)`,
            gridAutoRows: `${stikerHeight}px`,
          }}
        >
          {renderQrModal()}
        </div>
        <Button
          variant="contained"
          size="small"
          className="non"
          sx={{ m: 0, ml: 2, mt: 4 }}
          onClick={() => {
            handlePrint();
            countOfStickerAnalytics(certificateIdsArray);
          }}
        >
          Print
        </Button>
      </div>
    </TableContainer>
  );
}
